/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
import Typography from '@atoms/Typography/Typography'
import classNames from 'classnames'
import React, { forwardRef, ReactNode } from 'react'
import { Tooltip } from 'react-tooltip'

interface Props {
  label: string
  value: string
  options: any[]
  addText?: string
  isRequired?: boolean
  layout?: string
}
export type Ref = HTMLInputElement

export default forwardRef<Ref, Props>((props, ref) => {
  const { label, addText, value, options, isRequired, layout = 'horizontal', ...rest } = props
  return (
    <div {...rest} className="radio">
      <label>
        <Typography className={classNames(['text_medium__14', !addText && 'remo-form-label'])}>
          {label} {isRequired && <span className="text-danger">*</span>}
        </Typography>
        {addText && (
          <Typography
            className="text_light__12 color_text_300 mt-1"
            style={{ marginBottom: 12, whiteSpace: 'pre-line' }}
          >
            {addText}
          </Typography>
        )}
        <div
          className={`d-flex gap-2 ${layout === 'vertical' ? 'flex-column align-items-start' : 'align-items-center'}`}
        >
          {options?.map((option) => (
            <div key={option.id} className="d-flex align-items-center gap-1">
              <Typography as="label" component="label" htmlFor={option.id} className="d-flex text_regular__14">
                <input
                  data-testid="RadioButton-CC9163"
                  ref={ref}
                  type="radio"
                  checked={value ? String(value) === String(option.value) : false}
                  id={option.id}
                  value={option.value}
                  {...rest}
                />
                <span className="ml-1">{option.text}</span>
              </Typography>
              {option.tooltipText && <Tooltip id={option.id} content={option.tooltipText} />}
            </div>
          ))}
        </div>
      </label>
    </div>
  )
})
