/* eslint-disable react/no-unstable-nested-components */
import Avatar from '@atoms/Avatar/Avatar'
import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Status from '@atoms/Status/Status'
import CollapseTable from '@atoms/Table/CollapseTable'
import SelectTable from '@atoms/Table/SelectTable'
import MainTable from '@atoms/Table/Table'
import Typography from '@atoms/Typography/Typography'
import * as icons from '@core/icons/icons'
import classNames from 'classnames'
import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { Flag } from '../../components/flag/flag.component'

export default function CustomTable({
  fields,
  data,
  onRowClick,
  onChangeSelect,
  customSelectedIds,
  type,
  onCollapseClick,
  id,
  pageSize,
  loading,
  page,
  total,
  collapseId,
  onPage,
}) {
  const columns = React.useMemo(
    () =>
      fields.map((field) => {
        switch (field.type) {
          case 'country':
            return {
              Header: field.title,
              accessor: field.key,
              maxWidth: field.maxWidth,
              minWidth: field.minWidth,
              width: field.width,
              Cell: ({ row: { values } }) => {
                return (
                  <div>
                    <Flag code={values?.country?.phone_code && values?.country?.phone_code.toLowerCase()} />
                    <span>{values?.country?.name}</span>
                  </div>
                )
              },
            }
          case 'status':
            return {
              Header: 'Status',
              accessor: 'status',
              maxWidth: field.maxWidth,
              minWidth: field.minWidth,
              width: field.width,
              Cell: ({ row }) => {
                return (
                  <Status
                    status={row.values.status}
                    isOnboardingTab={row.original.onboarding}
                    documents_state={row.original.documents_state}
                  />
                )
              },
            }
          case 'status_invoice':
            return {
              Header: 'Status',
              accessor: 'status',
              maxWidth: field.maxWidth,
              minWidth: field.minWidth,
              width: field.width,
              Cell: ({ row }) => {
                return (
                  <Status
                    status={row.values.status === 'created' ? 'pending_your_payment' : row.values.status}
                    isOnboardingTab={row.original.onboarding}
                    documents_state={row.original.documents_state}
                  />
                )
              },
            }
          case 'textMedium':
            return {
              Header: () => {
                return (
                  <>
                    <span>{field.title}</span>
                    {field.hasTooltip && (
                      <OverlayTrigger placement="bottom" overlay={<Tooltip>{field.tooltipText}</Tooltip>}>
                        <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
                          <Icon icon={icons.helpIcon} />
                        </span>
                      </OverlayTrigger>
                    )}
                  </>
                )
              },
              accessor: field.key,
              maxWidth: field.maxWidth,
              minWidth: field.minWidth,
              width: field.width,
              Cell: ({ row }) => (
                <div className="d-flex align-items-center">
                  {field.icon && <Icon icon={icons[field.icon]} />}
                  <Typography className={classNames(['text_medium__14', field.icon && 'ml-2'])}>
                    {row.values[field.key]}
                  </Typography>
                  <span style={{ marginLeft: 16 }}>{row.original.from_remofirst && field.iconRight}</span>
                </div>
              ),
            }
          case 'textAction':
            return {
              Header: field.title,
              accessor: field.key,
              maxWidth: field.maxWidth,
              minWidth: field.minWidth,
              width: field.width,
              Cell: ({ row }) => (
                <div style={{ height: 44 }} className="d-flex flex-column text-left">
                  <div className="d-flex flex-row">
                    <Typography className={classNames(['text_regular__14'])}>{row.values[field.key]}</Typography>
                    {field.add_text && (
                      <Typography as="span" className="text_regular__14 color_text_300 ml-1">
                        {row.original[field.add_text]}
                      </Typography>
                    )}
                  </div>
                  {field?.action && (
                    <Button
                      style={{
                        maxWidth: 'fit-content',
                        height: 22,
                        fontWeight: 400,
                      }}
                      className="p-0"
                      priority="outlined"
                      size="small"
                      onClick={() => field?.action({ row, key: field.key })}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              ),
            }
          case 'avatar':
            return {
              Header: field.title,
              accessor: field.key,
              maxWidth: field.maxWidth,
              minWidth: field.minWidth,
              width: field.width,
              Cell: ({ row }) => (
                <div className="d-flex align-items-center my-4">
                  <Avatar className="my-auto" src={row.original?.avatar} width={48} height={48} />
                  <div className="d-flex flex-column ml-3">
                    <Typography className="text_medium__14 d-flex">
                      {row.values[field.key]}
                      {field.add_tag && row.original[field.add_tag] && (
                        <Typography className="additional_tag">{row.original[field.add_tag]}</Typography>
                      )}
                    </Typography>
                    {field.add_key && (
                      <Typography className="text_regular__14 color_text_300">{row.original[field.add_key]}</Typography>
                    )}
                    {field.action && (
                      <Button
                        style={{ maxWidth: 'fit-content' }}
                        priority="outlined"
                        size="small"
                        onClick={() => field?.action({ row, key: field.key })}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                </div>
              ),
            }
          case 'actions':
            return {
              Header: field.title,
              accessor: 'actions',
              maxWidth: field.maxWidth,
              minWidth: field.minWidth,
              width: field.width,
              Cell: ({ row }) => {
                return (
                  <div className="d-flex">
                    {row.values.status === 'generating' && field.actions[0].visibleWithStateGenerate ? (
                      <div style={{ width: 79.68 }} />
                    ) : (
                      <Button
                        priority={field?.actions[0]?.actionText ? 'secondary' : 'outlined'}
                        disabled={
                          (field.actions[0].visible ? false : row.original.disabled && !field.actions[1]?.active) ||
                          row.original.resetSendButton
                        }
                        active={row?.original?.id === collapseId}
                        style={{
                          height: '32px',
                          minWidth: field.actions[0]?.afterDisabledActionText ? 146 : 'fit-content',
                        }}
                        size="small"
                        className="px-2 mr-2 pl-0"
                        id={field.actions[0].icon}
                        onClick={() => field.actions[0].action(row.original)}
                      >
                        {row.original.resetSendButton ? (
                          field.actions[0]?.afterDisabledActionText
                        ) : (
                          <>
                            {field.actions[0].icon && (
                              <Icon
                                className={field.actions[0]?.icon === 'blockIcon' ? 'ml-2 mt-2' : ''}
                                fill="none"
                                icon={icons[field.actions[0].icon]}
                              />
                            )}
                            {field?.actions[0]?.actionText && (
                              <Typography className={classNames('text_light__12', field.actions[0].icon && 'ml-2')}>
                                {field?.actions[0]?.actionText}
                              </Typography>
                            )}
                          </>
                        )}
                      </Button>
                    )}
                    {field.actions[1] &&
                      (row.values.status === 'generating' && field.actions[1].visibleWithStateGenerate ? (
                        <div style={{ width: 79.68 }} />
                      ) : (
                        <Button
                          priority={field?.actions[1]?.actionText ? 'secondary' : 'outlined'}
                          style={{ height: '32px' }}
                          size="small"
                          disabled={row.original.disabled && !field.actions[1].active}
                          className="px-2 mr-2 pl-0"
                          id={field.actions[1].icon}
                          onClick={() => field.actions[1].action(row.original)}
                        >
                          {icons[field.actions[1].icon] && <Icon fill="none" icon={icons[field.actions[1].icon]} />}
                          {field?.actions[1]?.actionText && (
                            <Typography className={classNames('text_light__12', field.actions[1].icon && 'ml-2')}>
                              {field?.actions[1]?.actionText}
                            </Typography>
                          )}
                        </Button>
                      ))}
                    {field.actions[2] && (
                      <Button
                        priority={field?.actions[2]?.actionText ? 'secondary' : 'outlined'}
                        style={{ height: '32px' }}
                        size="small"
                        disabled={row.original.disabled && !field.actions[1].active}
                        className="px-2 mr-2 pl-0"
                        id={field.actions[2].icon}
                        onClick={() => field.actions[2].action(row.original)}
                      >
                        {icons[field.actions[2].icon] && <Icon fill="none" icon={icons[field.actions[2].icon]} />}
                        {field?.actions[2]?.actionText && (
                          <Typography className={classNames('text_light__12', field.actions[2].icon && 'ml-2')}>
                            {field?.actions[2]?.actionText}
                          </Typography>
                        )}
                      </Button>
                    )}
                  </div>
                )
              },
            }
          case 'custom':
            return {
              Header: field.title,
              accessor: field.key,
              maxWidth: field.maxWidth,
              minWidth: field.minWidth,
              width: field.width,
              Cell: ({ row }) => {
                return field.render(row.original, row.index)
              },
            }
          case 'amount_in_company_currency':
            return {
              Header: () => {
                return (
                  <>
                    <span>{field.title}</span>
                    {field.hasTooltip && (
                      <OverlayTrigger placement="top" overlay={<Tooltip>{field.tooltipText}</Tooltip>}>
                        <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
                          <Icon icon={icons.helpIcon} />
                        </span>
                      </OverlayTrigger>
                    )}
                  </>
                )
              },
              accessor: field.key,
              maxWidth: field.maxWidth,
              minWidth: field.minWidth,
              width: field.width,
              Cell: ({ row }) => {
                return field.render(row.original)
              },
            }
          default:
            return {
              Header: field.title,
              accessor: field.key,
              maxWidth: field.maxWidth,
              minWidth: field.minWidth,
              width: field.width,
            }
        }
      }),
    [fields, collapseId]
  )

  const renderTable = () => {
    switch (type) {
      case 'select':
        return (
          <SelectTable
            loading={loading}
            columns={columns}
            data={data}
            page={page}
            customSelectedIds={customSelectedIds}
            total={total}
            onPage={onPage}
            onChangeSelect={onChangeSelect}
          />
        )
      case 'main':
        return (
          <MainTable
            loading={loading}
            page={page}
            pageSize={pageSize}
            total={total}
            columns={columns}
            data={data}
            onRowClick={onRowClick}
            onPage={onPage}
          />
        )
      case 'collapse':
        return (
          <CollapseTable
            columns={columns}
            data={data}
            id={id}
            collapseId={collapseId}
            onCollapseClick={onCollapseClick}
            page={page}
            pageSize={pageSize}
            total={total}
            onPage={onPage}
          />
        )
      default:
        return (
          <MainTable
            loading={loading}
            page={page}
            pageSize={pageSize}
            total={total}
            columns={columns}
            data={data}
            onRowClick={onRowClick}
            onPage={onPage}
          />
        )
    }
  }

  return renderTable()
}
