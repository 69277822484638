import Button from '@atoms/Button/Button'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { SwiftFeePayrollLabel } from '@pages/profilePage/mock'
import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { v4 } from 'uuid'

const formId = v4()

const BANK_ACCOUNT = {
  Local: 'local',
  Global: 'global',
}

const BANK_OPTIONS = {
  [BANK_ACCOUNT.Global]: {
    id: BANK_ACCOUNT.Global,
    text: 'Global',
    value: BANK_ACCOUNT.Global,
    tooltipText: 'Payment to Remofirst will be made via international wire transfer.',
  },
  [BANK_ACCOUNT.Local]: {
    id: BANK_ACCOUNT.Local,
    text: 'Local',
    value: BANK_ACCOUNT.Local,
    tooltipText: 'Payment to Remofirst will be processed through local bank transfers.',
  },
}

const getBankAccountType = (currency) => {
  switch (currency) {
    case 'GBP':
    case 'USD':
    case 'EUR':
      return [BANK_OPTIONS[BANK_ACCOUNT.Local], BANK_OPTIONS[BANK_ACCOUNT.Global]]
    case 'INR':
    case 'SGD':
      return [BANK_OPTIONS[BANK_ACCOUNT.Local]]
    case 'HKD':
    case 'AUD':
    case 'NZD':
    case 'JPY':
      return [BANK_OPTIONS[BANK_ACCOUNT.Global]]
    default:
      return [BANK_OPTIONS[BANK_ACCOUNT.Global]]
  }
}
export const PaymentTermsEditForm = ({ onClose, onNext }) => {
  const { profile } = useContext(AppContext)

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      invoice_settings: {
        swift_fee_pay_role: profile?.invoice_settings.swift_fee_pay_role || 'local',
        bank_account_type: profile?.invoice_settings.bank_account_type || '',
      },
    },
  })
  const currency = profile?.invoice_settings?.contractor_default_currency?.short_code

  const onSubmit = ({ invoice_settings }) => {
    const { bank_account_type, swift_fee_pay_role } = invoice_settings
    onNext({
      invoice_settings: {
        bank_account_type,
        swift_fee_pay_role: swift_fee_pay_role.value || swift_fee_pay_role,
      },
    })
  }

  return (
    <Modal show centered onHide={onClose} animation={false}>
      <Modal.Header>Contractor payment</Modal.Header>
      <Modal.Body>
        <form id={formId} className="d-flex flex-column w-100" onSubmit={handleSubmit(onSubmit)}>
          {/* <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="invoice_settings.bank_account_type"
              render={({ field }) => (
                <RadioButton
                  {...field}
                  label="Bank account type "
                  addText="Which of the following options apply to you?"
                  options={getBankAccountType(currency)}
                />
              )}
            />
            {errors.invoice_settings?.bank_account_type && (
              <Typography className="text_regular__14 color_red">
                {errors.invoice_settings.bank_account_type.message}
              </Typography>
            )}
          </div> */}
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="invoice_settings.swift_fee_pay_role"
              rules={{
                required: 'International wire transfer (SWIFT) Fee is required',
              }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="PaymentTermsEditForm-DD1FC9"
                    {...field}
                    isRequired
                    label="International wire transfer (SWIFT) Fee"
                    addText="Please indicate who should be responsible for covering the SWIFT fees"
                    options={Object.entries(SwiftFeePayrollLabel)
                      .slice(0, 3)
                      .map(([key, value]) => ({ value: key, label: value }))}
                  />
                )
              }}
            />
            <Typography className="text_light__12 color_text_300 mt-1" style={{ marginBottom: 12 }}>
              If contractor payment will be done via International wire transfer, the payment rails for contractor
              payments will utilize SWIFT wire transfers. SWIFT fee is fixed at 20 USD per transaction
            </Typography>
            {errors.invoice_settings?.swift_fee_pay_role && (
              <Typography className="text_regular__14 color_red">
                {errors.invoice_settings.swift_fee_pay_role.message}
              </Typography>
            )}
          </div>
        </form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="PaymentTermsEditForm-907170"
          size="small"
          color="primary_black"
          type="button"
          priority="secondary"
          className="mr-2"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button data-testid="PaymentTermsEditForm-38FBE1" size="small" type="submit" form={formId}>
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
