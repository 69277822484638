import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { downloadIcon, keyboardArrowLeft } from '@core/icons/icons'
import { getSubInvoices } from '@services/aggregated-invoices.service'
import moment from 'moment'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'

export const SubInvoicesPage = () => {
  const { id } = useParams()
  const [page, setPage] = useState(1)
  const { data, isLoading, isFetching } = useQuery(['sub-invoices', id], () => getSubInvoices(id))

  const onClickDownload = (row) => {
    window.open(row.file, '_blank')
  }

  const fields = [
    {
      title: 'ID',
      key: 'id',
      maxWidth: 100,
      minWidth: 80,
      width: 80,
    },
    {
      title: 'Employee',
      key: 'employee.full_name',
      maxWidth: 180,
      type: 'custom',
      minWidth: 180,
      width: 180,
      render: (row) => {
        return <Typography className="text_regular__14 employee-label">{`${row.employee.full_name}`}</Typography>
      },
    },
    {
      title: 'Contract currency',
      key: 'currency.name',
      type: 'string',
      maxWidth: 160,
      minWidth: 160,
      width: 160,
    },
    {
      title: 'Contract amount',
      key: 'amount_in_company_currency',
      type: 'amount_in_company_currency',
      hasTooltip: true,
      tooltipText: 'This is the amount due in the currency agreed in the contract between you and the contractor.',
      maxWidth: 138,
      minWidth: 138,
      width: 138,
      render: (row) => {
        return (
          <Typography className="text_regular__14">{`${row.amount_in_company_currency} ${row.currency.sign}`}</Typography>
        )
      },
    },
    {
      title: 'Status',
      key: 'status',
      type: 'status_invoice',
      maxWidth: 180,
      minWidth: 180,
      width: 180,
    },
    {
      title: 'Invoice',
      key: 'custom',
      type: 'custom',
      maxWidth: 180,
      minWidth: 180,
      width: 180,
      render: (row) => (
        <div className="d-flex align-items-center">
          {row.status !== 'generating' && row.file && (
            <div className="d-flex align-items-center mr-2">
              <Button
                data-testid="SubInvoiceDownload-41564C"
                className="text_light__12 px-2 pl-0"
                priority="secondary"
                size="small"
                style={{ height: '32px' }}
                loading={isLoading}
                onClick={() => onClickDownload(row)}
              >
                <Icon icon={downloadIcon} className="mr-2" />
                Download
              </Button>
            </div>
          )}
        </div>
      ),
    },
  ]

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'baseline', gap: 12 }}>
        <Link className="d-flex align-items-center justify-content-center back-btn" role="button" to="/pages/invoices/">
          <Icon icon={keyboardArrowLeft} style={{ transform: 'rotate(180deg)' }} />
        </Link>
        {data && (
          <PageTitle styleClass="mb-4">{`Invoice details - ${moment(data?.date).format('DD MMM YYYY')} | ${data.total} ${data.currency.sign}`}</PageTitle>
        )}
      </div>
      <CustomTable
        fields={fields}
        total={data?.invoices.length || 0}
        pageSize={20}
        page={page}
        loading={isLoading || isFetching}
        data={data?.invoices || []}
        onPage={setPage}
      />
    </div>
  )
}
