import './OverviewPage.scss'

import Button from '@atoms/Button/Button'
import ChooseMeetingTime from '@atoms/ChooseMeetingTimeModal'
import Icon from '@atoms/Icon/Icon'
import NotFound from '@atoms/NotFound/NotFound'
import Typography from '@atoms/Typography/Typography'
import { Card } from '@components/card/card.component'
import { CardContent } from '@components/card/card-content.component'
import { CardHeader } from '@components/card/card-header.component'
import { WelcomeTitle } from '@components/welcome-title/welcome-title.component'
import { COMPANY_STATES, EOR_TYPES, USER_GROUPS } from '@core/constants'
import { useApp } from '@core/context'
import * as icons from '@core/icons/icons'
import { addContractor } from '@core/icons/icons'
import { SplitAmount, SplitNumber, userHasOneOfGroupsOrSuper } from '@core/utils'
import { CostCalculatorCard } from '@features/cost-calculator-card/cost-calculator-card.component'
import { CountryGuideCard } from '@features/country-guide-card/country-guide-card.component'
import { DashboardFte } from '@features/dashboard-fte/dashboard-fte.component'
import { OnboardingChecklist } from '@features/onboarding-checklist/onboarding-checklist.component'
import { ContractorsMassImportModal } from '@pages/employees/contractors-mass-import-modal/contractors-mass-import-modal.component'
import CreateContractorModal from '@pages/employees/create-contractor/CreateContractorModal'
import CreateEmployeeModal from '@pages/employees/create-employee/CreateEmployeeModal'
import { getOnboardingChecklist } from '@services/company.service'
import { getFeatureFlagList } from '@services/feature-flag.service'
import { fetchCreateContractor } from '@store/contracts'
import { DotWave } from '@uiball/loaders'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'
import { v4 as uuidv4 } from 'uuid'

import { fetchStats } from '../../services/stats.service'
import CalendarStepper from './CalendarStepper'
import { CompleteOnboardingModal } from './complete-onboarding-modal/complete-onboarding-modal.component'
import PaymentCard from './PaymentCard'

const InfoCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`

export default function OverviewPage() {
  const navigate = useNavigate()
  const { profile, refetchProfile, userProfile, userGroups } = useApp()

  const bookMeetModalState = useBoolean(false)
  const newContractorModalState = useBoolean(false)
  const newEmployeeModalState = useBoolean(false)
  const completeOnboardingModalState = useBoolean(false)
  const massImportModalState = useBoolean(false)
  const continueContract = useBoolean(false)
  const [addedNewEmployee, setAddedNewEmployee] = useState(null)

  const {
    data: stats,
    isLoading: isLoadingStats,
    refetch,
  } = useQuery('stats', {
    queryFn: () => fetchStats(),
  })

  const { data: featureFlag } = useQuery('getFeatureFlagList', () => getFeatureFlagList())
  const getFeature = featureFlag?.filter((ff) => ff.feature_name === 'client_payroll_time_line')
  const showFeature =
    getFeature &&
    getFeature[0] &&
    (getFeature[0].companies_full_access || getFeature[0].companies.some((partner) => partner.id === profile.id))
  const { isLoading: isLoadingChecklist, data: onboardingChecklist } = useQuery(
    ['getOnboardingChecklist', profile.id],
    {
      queryFn: () => getOnboardingChecklist(profile.id),
      onSuccess: (res) => {
        if (res.check_list_passed) {
          refetchProfile()
        }
      },
    }
  )

  console.log('onboardingChecklist', onboardingChecklist)

  const newEmployeeMutation = useMutation({
    mutationFn: (payload) => fetchCreateContractor(payload),
    onSuccess: (v) => {
      newEmployeeModalState.setFalse()
      refetch()
      // navigate(`/pages/team`)
      setAddedNewEmployee(v)
      if (v.contract_type === 'contractor') continueContract.setTrue()
    },
  })

  const handleGoToMeet = () => {
    navigate('/calendly?page=dashboard')
  }

  const handleSaveNewEmployee = (payload) => {
    newEmployeeMutation.mutate({
      ...payload,
      company: profile.id,
    })
  }

  const AddEmployeeBtn = useMemo(() => {
    if (profile?.source === 'self_onboarded') {
      if (profile.state !== 'active') {
        return (
          <Button
            disabled={process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'stage'}
            data-testid="OverviewPage-26C36F"
            className="align-self-end"
            size="small"
            priority="primary_black"
            onClick={bookMeetModalState.setTrue}
          >
            Book a meeting
          </Button>
        )
      }
      if (!profile.is_verified) {
        return (
          <Button
            disabled={process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'stage'}
            data-testid="OverviewPage-EA0791"
            className="align-self-end"
            size="small"
            priority="primary_black"
            onClick={bookMeetModalState.setTrue}
          >
            Book a meeting
          </Button>
        )
      }
    }

    return (
      <Button
        data-testid="OverviewPage-EFDE79"
        className="align-self-end"
        size="small"
        priority="secondary"
        onClick={newEmployeeModalState.setTrue}
        hidden={!userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST])}
      >
        <Icon icon={icons.add} className="mr-2" />
        Add employee
      </Button>
    )
  }, [bookMeetModalState, newEmployeeModalState])

  const getDescriptionText = (index) => {
    if (index === 0 && profile?.source === 'self_onboarded' && profile.state !== 'active')
      return 'Book a meeting with Remofirst team to discuss Partnership.'
    if (index === 0) return 'Add full time employees to start working with them using the platform.'
    if (index === 1) return 'Add contractors to start working with them using the platform.'
    return ''
  }

  const handleCloseNewEmployeeModal = () => {
    newEmployeeModalState.setFalse()
    newContractorModalState.setFalse()
  }

  const handleAddContractor = () => {
    if (profile?.source === 'self_onboarded' && profile.state !== 'active') {
      completeOnboardingModalState.setTrue()
    } else {
      newContractorModalState.setTrue()
    }
  }

  function payrolls() {
    return (
      <div className="overview-page-stats">
        <Typography className="heading_semibold__18" style={{ marginBottom: 12 }}>
          Payroll expenses
        </Typography>
        {stats.payrolls.data.length > 0 ? (
          <>
            <Typography className="heading_semibold__32" style={{ marginBottom: 12 }}>
              {stats.payrolls.currency.sign || stats.payrolls.currency.short_code}
              {SplitNumber(stats.payrolls.total_amount)}
            </Typography>
            <div className="d-flex">
              {stats.payrolls.percentage !== 0 && (
                <Icon icon={icons[stats.payrolls.is_more ? 'arrowUp' : 'arrowDownPercent']} />
              )}
              <Typography
                className={classNames([
                  'text_medium__14 d-flex ml-1',
                  stats.payrolls.is_more ? 'color_primary' : 'color_red',
                ])}
              >
                <span
                  className={classNames({
                    color_grey: stats.payrolls.percentage === 0,
                  })}
                >
                  {stats.payrolls.percentage} %
                </span>
                <span className="ml-2 text_medium__14 color_text_300">vs last month</span>
              </Typography>
            </div>

            <div className="divider" style={{ margin: '12px 0' }} />
            <Typography className="heading_semi__16 mb-3">Сountry Payroll</Typography>
            {stats.payrolls.data.map((country) => (
              <div className="mb-3 d-flex" key={uuidv4()}>
                <span
                  className={classNames([
                    'fi flag-icons',
                    `fi-${country.working_country ? country.working_country.phone_code.toLowerCase() : 'us'}`,
                  ])}
                />
                <div className="ml-3 w-100">
                  <div className="d-flex justify-content-between mb-2">
                    <Typography className="text_regular__14">{country.working_country.name}</Typography>
                    <Typography className="text_regular__14">
                      {stats.payrolls.currency.sign || stats.payrolls.currency.short_code} {SplitNumber(country.amount)}
                    </Typography>
                  </div>
                  <ProgressBar
                    bsPrefix="overview-progress"
                    now={(country.amount * 100) / stats.payrolls.total_amount}
                    visuallyHidden
                  />
                </div>
              </div>
            ))}
          </>
        ) : (
          <NotFound title="No payrolls yet" description="They will appear here in the next billing period" />
        )}
      </div>
    )
  }

  function activeWorkers() {
    return (
      <div className="overview-page-stats overview-page-active-workers">
        <Typography className="heading_semibold__18" style={{ marginBottom: 12 }}>
          Active workers
        </Typography>

        <Typography className="heading_semibold__32" style={{ marginBottom: 12 }}>
          {SplitAmount(stats.active_workers.total)}
        </Typography>
        <div className="d-flex">
          {stats.active_workers.percentage !== 0 && (
            <Icon icon={icons[stats.active_workers.is_more ? 'arrowUp' : 'arrowDownPercent']} />
          )}
          <Typography
            className={classNames([
              'text_medium__14 d-flex ml-1',
              stats.active_workers.is_more ? 'color_primary' : 'color_red',
            ])}
          >
            <span
              className={classNames({
                color_grey: stats.active_workers.percentage === 0,
              })}
            >
              {stats.active_workers.percentage} %
            </span>
            <span className="ml-2 text_medium__14 color_text_300">vs last month</span>
          </Typography>
        </div>
        <div className="divider" style={{ margin: '12px 0' }} />
        <Typography className="heading_semi__16 mb-3">Workers` countries</Typography>
        {stats.active_workers.data.map((country) => (
          <div className="mb-3 d-flex" key={uuidv4()}>
            <span
              className={classNames([
                'fi flag-icons',
                `fi-${country.working_country ? country.working_country.phone_code.toLowerCase() : 'us'}`,
              ])}
            />
            <div className="ml-3 w-100">
              <div className="d-flex justify-content-between mb-2">
                <Typography className="text_regular__14">{country.working_country.name}</Typography>
                <Typography className="text_regular__14">{country.amount}</Typography>
              </div>
              <ProgressBar
                bsPrefix="overview-progress"
                now={(country.amount * 100) / stats.active_workers.total}
                visuallyHidden
              />
            </div>
          </div>
        ))}
      </div>
    )
  }

  const isDashboardFteVisible = () => {
    if (
      profile?.eor_type === EOR_TYPES.SAAS &&
      (profile?.state !== COMPANY_STATES.ACTIVE || !profile?.msa_agreement_document_signed)
    ) {
      return true
    }
    return false
  }

  if (isLoadingChecklist || isLoadingStats) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center" style={{ height: 200 }}>
        <DotWave size={48} speed={1} color="black" />
      </div>
    )
  }

  return (
    <>
      <div className="overview-page d-flex flex-column w-100 h-100">
        <div className="overview-page__header">
          <WelcomeTitle
            firstName={userProfile?.profile?.first_name || userProfile?.profile?.last_name}
            description="We hope you're having a good day!"
          />
        </div>
        {showFeature && <CalendarStepper />}
        {profile?.source === 'self_onboarded' && !onboardingChecklist?.check_list_passed ? (
          <Card>
            <CardHeader
              title="Account set up checklist"
              description="Please complete all of these steps to activate your account."
            />
            <CardContent>
              <OnboardingChecklist checklist={onboardingChecklist?.check_list} />
            </CardContent>
          </Card>
        ) : null}
        <Card>
          {isDashboardFteVisible() && (
            <>
              <CardHeader
                title="Full-time employees"
                description={<span>Please finish the steps below in order to start hiring full-time employees.</span>}
              />
              <CardContent>
                <InfoCards>
                  <CostCalculatorCard />
                  <CountryGuideCard />
                </InfoCards>
                <DashboardFte />
              </CardContent>
            </>
          )}
          {stats &&
            stats.employees.map((employee, index) => {
              return index === 0 && isDashboardFteVisible(employee.count) ? null : (
                <div className="mb-4" key={uuidv4()}>
                  <CardHeader title={employee.employee} description={getDescriptionText(index)} />

                  <CardContent>
                    <Card color="light">
                      <div className="d-flex">
                        <img src={employee.icon} alt={employee.employee} />
                        <Typography className="heading_semibold__28 ml-3">{SplitAmount(employee.count)}</Typography>
                      </div>

                      <div className="d-flex flex-grow-1 align-items-end align-items-end justify-content-between">
                        <div className="d-flex">
                          {employee.percentage !== 0 && (
                            <Icon icon={icons[employee.is_more ? 'arrowUp' : 'arrowDownPercent']} />
                          )}
                          <Typography
                            className={classNames([
                              'text_medium__14 d-flex pl-1',
                              employee.is_more ? 'color_primary' : 'color_red',
                            ])}
                          >
                            <span
                              className={classNames({
                                color_grey: employee.percentage === 0,
                              })}
                            >
                              {employee.percentage} %
                            </span>
                            <span className="ml-2 text_medium__14 color_text_300">vs last month</span>
                          </Typography>
                        </div>
                        {index === 0 && AddEmployeeBtn}
                        {index === 1 && (
                          <div>
                            <Button
                              data-testid="OverviewPage-9937B4"
                              className="align-self-end"
                              size="small"
                              priority="secondary"
                              onClick={handleAddContractor}
                              hidden={!userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST])}
                            >
                              <Icon icon={addContractor} className="mr-2" />
                              Add contractors
                            </Button>
                          </div>
                        )}
                      </div>
                    </Card>
                  </CardContent>
                </div>
              )
            })}
        </Card>
        {userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.FINANCE_AP_SPECIALIST]) && <PaymentCard />}
        <div className="mt-4 w-100">
          <div className="row">
            <div
              className={classNames('col-sm-12', {
                'col-md-7': stats.active_workers.data.length > 0,
              })}
            >
              {payrolls()}
            </div>
            {stats.active_workers.data.length > 0 && <div className="col-sm-12 col-md-5">{activeWorkers()}</div>}
          </div>
        </div>
      </div>
      {bookMeetModalState.value && <ChooseMeetingTime onClose={bookMeetModalState.setFalse} onMeet={handleGoToMeet} />}
      {newContractorModalState.value && (
        <CreateContractorModal
          addedNewEmployee={addedNewEmployee}
          isContinuedContract={continueContract.value}
          onCloseContractModal={continueContract.setFalse}
          loading={newEmployeeMutation.isLoading}
          onClose={handleCloseNewEmployeeModal}
          onSave={handleSaveNewEmployee}
        />
      )}

      {newEmployeeModalState.value && (
        <CreateEmployeeModal
          loading={newEmployeeMutation.isLoading}
          onClose={newEmployeeModalState.setFalse}
          onSave={handleSaveNewEmployee}
        />
      )}

      {completeOnboardingModalState.value && (
        <CompleteOnboardingModal onClose={completeOnboardingModalState.setFalse} />
      )}

      {massImportModalState.value && <ContractorsMassImportModal onClose={massImportModalState.setFalse} />}
    </>
  )
}
