import moment from 'moment'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export const payrollsField = (onClickEdit, handleDelete) => [
  {
    title: 'Name',
    key: 'name',
    type: 'custom',
    maxWidth: 300,
    minWidth: 300,
    width: 300,
    render: (item) => (
      <div>
        {item.name}
        {item.indication !== 'regular' && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                {item.indication === 'first'
                  ? `First payroll of the employee starting on ${
                      item?.job?.starts_at ? moment(item?.job?.starts_at).format('DD MMM YYYY') : ''
                    }`
                  : `Last payroll of the employee terminating on ${
                      item?.job?.ends_at ? moment(item?.job?.ends_at).format('DD MMM YYYY') : ''
                    }`}
              </Tooltip>
            }
          >
            <span
              className={`text_regular__14 ${item.indication === 'first' ? 'color_blue' : 'color_red'}`}
              style={{
                marginLeft: 4,
                verticalAlign: 'top',
                padding: 4,
                border: `1px solid ${item.indication === 'first' ? '#2967bc' : '#ef4343'}`,
              }}
            >
              {item.indication === 'first' ? 'First payroll' : 'Terminating'}
            </span>
          </OverlayTrigger>
        )}
      </div>
    ),
  },
  {
    title: 'Country',
    key: 'country',
    type: 'string',
    maxWidth: 156,
    minWidth: 156,
    width: 156,
  },
  {
    title: 'Total amount',
    key: 'total_amount',
    type: 'string',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Month',
    key: 'month',
    type: 'string',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 156,
    minWidth: 156,
    width: 156,
  },
  {
    title: 'Action',
    key: 'action',
    type: 'actions',
    maxWidth: 160,
    minWidth: 160,
    width: 160,
    actions: [
      {
        action: onClickEdit,
        actionText: '+ Additional payment',
      },
      {
        active: true,
        action: handleDelete,
        icon: 'deleteIcon',
      },
    ],
  },
]

export const payrollsToApproveField = (onClickEdit, handleDelete) => [
  {
    title: 'Name',
    key: 'name',
    type: 'custom',
    maxWidth: 300,
    minWidth: 300,
    width: 300,
    render: (item) => (
      <div>
        {item.name}
        {item.indication !== 'regular' && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                {item.indication === 'first'
                  ? `First payroll of the employee starting on ${
                      item?.job?.starts_at ? moment(item?.job?.starts_at).format('DD MMM YYYY') : ''
                    }`
                  : `Last payroll of the employee terminating on ${
                      item?.job?.ends_at ? moment(item?.job?.ends_at).format('DD MMM YYYY') : ''
                    }`}
              </Tooltip>
            }
          >
            <span
              className={`text_regular__14 ${item.indication === 'first' ? 'color_blue' : 'color_red'}`}
              style={{
                marginLeft: 4,
                verticalAlign: 'top',
                padding: 4,
                border: `1px solid ${item.indication === 'first' ? '#2967bc' : '#ef4343'}`,
              }}
            >
              {item.indication === 'first' ? 'First payroll' : 'Terminating'}
            </span>
          </OverlayTrigger>
        )}
      </div>
    ),
  },
  {
    title: 'Country',
    key: 'country',
    type: 'string',
    maxWidth: 156,
    minWidth: 156,
    width: 156,
  },
  {
    title: 'Total amount',
    key: 'total_amount',
    type: 'string',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Month',
    key: 'month',
    type: 'string',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 156,
    minWidth: 156,
    width: 156,
  },
  {
    title: 'Action',
    key: 'action',
    type: 'actions',
    maxWidth: 160,
    minWidth: 160,
    width: 160,
    actions: [
      {
        action: onClickEdit,
        actionText: '+ Additional payment',
      },
      {
        active: true,
        action: handleDelete,
        icon: 'deleteIcon',
      },
    ],
  },
]

export const payrollsInvoicedField = [
  {
    title: 'Name',
    key: 'name',
    type: 'custom',
    maxWidth: 300,
    minWidth: 300,
    width: 300,
    render: (item) => (
      <div>
        {item.name}
        {item.indication !== 'regular' && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                {item.indication === 'first'
                  ? `First payroll of the employee starting on ${
                      item?.job?.starts_at ? moment(item?.job?.starts_at).format('DD MMM YYYY') : ''
                    }`
                  : `Last payroll of the employee terminating on ${
                      item?.job?.ends_at ? moment(item?.job?.ends_at).format('DD MMM YYYY') : ''
                    }`}
              </Tooltip>
            }
          >
            <span
              className={`text_regular__14 ${item.indication === 'first' ? 'color_blue' : 'color_red'}`}
              style={{
                marginLeft: 4,
                verticalAlign: 'top',
                padding: 4,
                border: `1px solid ${item.indication === 'first' ? '#2967bc' : '#ef4343'}`,
              }}
            >
              {item.indication === 'first' ? 'First payroll' : 'Terminating'}
            </span>
          </OverlayTrigger>
        )}
      </div>
    ),
  },
  {
    title: 'Country',
    key: 'country',
    type: 'string',
    maxWidth: 156,
    minWidth: 156,
    width: 156,
  },
  {
    title: 'Total amount',
    key: 'total_amount',
    type: 'string',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Month',
    key: 'month',
    type: 'string',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Invoice no',
    key: 'invoice_id',
    type: 'custom',
    maxWidth: 156,
    minWidth: 156,
    width: 156,
    render: (item) => <Link to="/pages/invoices/">{item.invoice_id}</Link>,
  },
]

export const payrollsGenerationField = (onClickEdit) => [
  {
    title: 'Name',
    key: 'name',
    type: 'custom',
    maxWidth: 300,
    minWidth: 300,
    width: 300,
    render: (item) => (
      <div>
        {item.name}
        {item.indication !== 'regular' && (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip>
                {item.indication === 'first'
                  ? `First payroll of the employee starting on ${
                      item?.job?.starts_at ? moment(item?.job?.starts_at).format('DD MMM YYYY') : ''
                    }`
                  : `Last payroll of the employee terminating on ${
                      item?.job?.ends_at ? moment(item?.job?.ends_at).format('DD MMM YYYY') : ''
                    }`}
              </Tooltip>
            }
          >
            <span
              className={`text_regular__14 ${item.indication === 'first' ? 'color_blue' : 'color_red'}`}
              style={{
                marginLeft: 4,
                verticalAlign: 'top',
                padding: 4,
                border: `1px solid ${item.indication === 'first' ? '#2967bc' : '#ef4343'}`,
              }}
            >
              {item.indication === 'first' ? 'First payroll' : 'Terminating'}
            </span>
          </OverlayTrigger>
        )}
      </div>
    ),
  },
  {
    title: 'Country',
    key: 'country',
    type: 'string',
    maxWidth: 156,
    minWidth: 156,
    width: 156,
  },
  {
    title: 'Team',
    key: 'team',
    type: 'string',
    maxWidth: 156,
    minWidth: 156,
    width: 156,
  },
  {
    title: 'Total amount',
    key: 'total_amount',
    type: 'string',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 156,
    minWidth: 156,
    width: 156,
  },
  {
    title: 'Action',
    key: 'action',
    type: 'actions',
    maxWidth: 160,
    minWidth: 160,
    width: 160,
    actions: [
      {
        active: true,
        visible: true,
        action: onClickEdit,
        actionText: '+ Additional payment',
      },
    ],
  },
]
