import NotFound from '@atoms/NotFound/NotFound'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import usePagination from '@core/hooks/usePagination'
import { getAgreement, getAgreements } from '@services/sign.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import { AgreementActions } from '../../../../features/agreements/agreement-actions/agreement-actions.component'
import { transformAgreements } from '../../../../features/agreements/agreements.utils'

const fields = [
  {
    title: 'Name',
    key: 'name',
    type: 'textMedium',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Employee',
    key: 'employee',
    type: 'text',
    maxWidth: 300,
    minWidth: 300,
    width: 300,
  },
  {
    title: 'Created',
    key: 'created',
    type: 'textMedium',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Actions',
    key: 'actions',
    type: 'custom',
    render: (item) => <AgreementActions item={item} />,
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
]

const StyledContainer = styled.div`
  table {
    th:last-child {
      div {
        justify-content: end;
      }
    }
  }
`

const StyledTopbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`

export default function Agreements({ employee }) {
  const [agreementId, setAgreementId] = useState()
  const [agreementDetail, setAgreementDetail] = useState()
  const [total, setTotal] = useState(0)

  const { page, limit, setPage } = usePagination({ page: 1, limit: 10 })

  const { isFetching, data: agreementsResponse } = useQuery(['getAgreements', employee.id, page, limit], {
    queryFn: () =>
      getAgreements({
        contract_id: employee.id,
        limit,
        offset: (page - 1) * limit,
      }),
    onSuccess: ({ count }) => {
      setTotal(count)
    },
  })

  const { isLoading } = useQuery(['getAgreement', agreementId], {
    queryFn: () => getAgreement(agreementId),
    onSuccess: (data) => {
      setAgreementDetail(data)
    },
    enabled: !!agreementId,
  })

  const handleCollapse = (row) => {
    if (row.id === agreementId) return
    setAgreementId(row.id)
  }

  return (
    <StyledContainer>
      <StyledTopbar>
        <PageTitle>Agreements</PageTitle>
      </StyledTopbar>
      {isFetching ? (
        <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: 200 }}>
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <div>
          {agreementsResponse?.results?.length > 0 ? (
            <CustomTable
              type="collapse"
              fields={fields}
              data={transformAgreements(agreementsResponse, agreementDetail, isLoading)}
              onCollapseClick={handleCollapse}
              page={page}
              total={total}
              onPage={setPage}
              pageSize={limit}
            />
          ) : (
            <div>
              <NotFound title="This employee does not have an agreement" />
            </div>
          )}
        </div>
      )}
    </StyledContainer>
  )
}
