/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import Button from '@atoms/Button/Button'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import ModalSide from '@atoms/ModalSide/ModalSide'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

export const ContractorsMassImportValidateModal = ({ contractor, isLoading, onSave, onClose, onDelete }) => {
  const { currencies } = useApp()

  const {
    control,
    register,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
  } = useForm({
    defaultValues: {
      ...contractor,
    },
  })

  useEffect(() => {
    clearErrors()
    contractor.errors.forEach(({ field, message }) => {
      setError(field, { type: 'value', message })
    })
  }, [contractor, setError, clearErrors])

  const handleSave = () => {
    const { errors, contractor_wage_type, contractor_payment_frequency, ...formValues } = getValues()

    onSave({
      ...formValues,
      contractor_wage_type: contractor_wage_type.value || contractor_wage_type,
      contractor_payment_frequency: contractor_payment_frequency.value || contractor_payment_frequency,
    })
  }

  return (
    <ModalSide
      title={`${contractor.first_name} ${contractor.last_name}`}
      footer={false}
      secondaryActions={[
        <Button
          data-testid="contractors-mass-import-validate-modal.component-011155"
          type="button"
          size="small"
          priority="secondary"
          onClick={onClose}
        >
          Close
        </Button>,
      ]}
      primaryActions={[
        <Button
          data-testid="contractors-mass-import-validate-modal.component-AB99EB"
          type="button"
          size="small"
          priority="danger"
          onClick={onDelete}
        >
          Remove this contractor from import
        </Button>,
        <Button
          data-testid="contractors-mass-import-validate-modal.component-F874D0"
          type="button"
          size="small"
          disabled={isLoading}
          onClick={handleSave}
        >
          Confirm
        </Button>,
      ]}
      onClose={onClose}
    >
      <div className="d-flex flex-column gap-2">
        <div>
          <Input
            data-testid="contractors-mass-import-validate-modal.component-147007"
            label="First name"
            isRequired
            {...register('first_name', { required: 'First name is required' })}
          />
          {errors.first_name && (
            <Typography className="text_regular__14 color_red">{errors.first_name.message}</Typography>
          )}
        </div>

        <div>
          <Input
            data-testid="contractors-mass-import-validate-modal.component-6D16B4"
            label="Last name"
            isRequired
            {...register('last_name', { required: 'Last name is required' })}
          />
          {errors.last_name && (
            <Typography className="text_regular__14 color_red">{errors.last_name.message}</Typography>
          )}
        </div>

        <div>
          <Input
            data-testid="contractors-mass-import-validate-modal.component-2DCE66"
            label="Email address"
            isRequired
            {...register('email', { required: 'Email address is required' })}
          />
          {errors.email && <Typography className="text_regular__14 color_red">{errors.email.message}</Typography>}
        </div>

        <div>
          <Input
            data-testid="contractors-mass-import-validate-modal.component-09D289"
            label="Tax residence"
            isRequired
            {...register('working_country', {
              required: 'Tax residence is required',
            })}
          />
          {errors.working_country && (
            <Typography className="text_regular__14 color_red">{errors.working_country.message}</Typography>
          )}
        </div>

        <div>
          <Input
            data-testid="contractors-mass-import-validate-modal.component-7EE6B8"
            label="Job title"
            isRequired
            {...register('position', {
              required: 'Job title is required',
            })}
          />
          {errors.position && <Typography className="text_regular__14 color_red">{errors.position.message}</Typography>}
        </div>

        <Input
          data-testid="contractors-mass-import-validate-modal.component-560FA8"
          label="Department"
          {...register('department')}
        />
        <Input
          data-testid="contractors-mass-import-validate-modal.component-9056E9"
          label="Job Description"
          {...register('position_description')}
        />

        <div>
          <Input
            data-testid="contractors-mass-import-validate-modal.component-9AE3A3"
            isRequired
            {...register('starts_at')}
            label="Start date"
          />
          {errors.starts_at && (
            <Typography className="text_regular__14 color_red">{errors.starts_at.message}</Typography>
          )}
        </div>

        <Controller
          name="contractor_wage_type"
          control={control}
          rules={{ required: 'Wage type is required' }}
          render={({ field }) => (
            <Select
              data-testid="contractors-mass-import-validate-modal.component-BCF7AD"
              {...field}
              isRequired
              label="Wage type"
              options={[
                { value: 'hourly', label: 'Hourly' },
                { value: 'monthly', label: 'Monthly' },
                { value: 'daily', label: 'Daily' },
              ]}
            />
          )}
        />
        {errors.contractor_wage_type && (
          <Typography className="text_regular__14 color_red">{errors.contractor_wage_type.message}</Typography>
        )}

        <Controller
          control={control}
          name="contractor_rate"
          rules={{
            required: 'Wage rate is required',
            validate: {
              minlength: (v) =>
                /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) || 'Only 2 digits allowed after decimal point',
            },
          }}
          render={({ field }) => (
            <CurrencyInput {...field} required label="Wage rate" onChange={setCurrencyChangeEvent(field.onChange)} />
          )}
        />
        {errors.contractor_rate && (
          <Typography className="text_regular__14 color_red">{errors.contractor_rate.message}</Typography>
        )}

        <div>
          <Input
            data-testid="contractors-mass-import-validate-modal.component-D39DB0"
            {...register('currency', {
              required: 'Contract currency is required',
            })}
            isRequired
            label="Contract currency"
          />
        </div>
        {errors.currency && <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>}

        <Controller
          name="contractor_payment_frequency"
          control={control}
          rules={{ required: 'Payment Frequency is required' }}
          render={({ field }) => (
            <Select
              data-testid="contractors-mass-import-validate-modal.component-54F6D8"
              {...field}
              isRequired
              label="Payment Frequency"
              options={[
                { value: 'weekly', label: 'Weekly' },
                { value: 'semi_monthly', label: 'Semi monthly' },
                { value: 'monthly', label: 'Monthly' },
                // { value: 'biweekly', label: 'Biweekly' },
              ]}
            />
          )}
        />
        {errors.contractor_payment_frequency && (
          <Typography className="text_regular__14 color_red">{errors.contractor_payment_frequency.message}</Typography>
        )}

        <div>
          <Input
            data-testid="contractors-mass-import-validate-modal.component-FF4051"
            {...register('estimated_working_hours')}
            label="Estimated Working Hours"
          />
        </div>
      </div>
    </ModalSide>
  )
}
