import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { Dropdown } from '@components/dropdown/dropdown.component'
import { fieldNameMap } from '@core/constants'
import { getJobDetailsList } from '@services/contract.service'
import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  .modal-header {
    margin-bottom: 0;
  }
  .modal-content {
    padding: 0;
  }
  .modal-body {
    position: relative;
  }
`

const FormControl = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`

export const JobDetailsEditForm = ({ title, data, onSubmit, onClose, isLoading }) => {
  const dropdownRef = useRef(null)
  const formRef = useRef(null)
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm()
  const jobTitle = watch('jobTitle')
  const dropdownOpenState = useBoolean(false)

  const jobDetailsListQuery = useQuery({
    queryKey: [getJobDetailsList.key],
    queryFn: getJobDetailsList.fetch,
  })

  useEffect(() => {
    reset(data)
  }, [data])

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      dropdownOpenState.setFalse()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const triggerFormSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  return (
    <StyledModal show onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>
      <Modal.Body>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <div className="w-100 remo-form-input" ref={dropdownRef}>
              <Dropdown
                open={dropdownOpenState.value}
                trigger={
                  <Input
                    data-testid="job-details-edit-form.component-74A899"
                    {...register('jobTitle', {
                      required: 'Job title is required',
                    })}
                    label="Job title"
                    type="text"
                    onFocus={dropdownOpenState.setTrue}
                    placeholder="Enter job title"
                  />
                }
                menu={
                  (jobDetailsListQuery?.data?.results.length &&
                    (jobTitle
                      ? // eslint-disable-next-line no-unsafe-optional-chaining
                        jobDetailsListQuery.data.results?.filter((f) =>
                          f.name?.toLowerCase().includes(jobTitle.toLowerCase())
                        )
                      : // eslint-disable-next-line no-unsafe-optional-chaining
                        jobDetailsListQuery.data.results || []
                    ).map(({ id, name, scope_of_work }) => (
                      <button
                        data-testid="job-details-edit-form.component-58D239"
                        key={id}
                        type="button"
                        onClick={() => {
                          dropdownOpenState.setFalse()
                          setValue('jobTitle', name)
                          setValue('jobDescription', scope_of_work)
                        }}
                      >
                        {name}
                      </button>
                    ))) ||
                  []
                }
              />
              {errors.jobTitle && (
                <Typography className="text_regular__14 color_red">{errors.jobTitle.message}</Typography>
              )}
            </div>
          </FormControl>
          <FormControl>
            <TextArea
              {...register('jobDescription')}
              type="text"
              maxLength={2000}
              label={fieldNameMap.jobDescription}
            />
          </FormControl>
          <FormControl>
            <Input
              data-testid="job-details-edit-form.component-EDC83C"
              {...register('department')}
              type="text"
              label={fieldNameMap.department}
            />
          </FormControl>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="job-details-edit-form.component-A7960C"
          type="button"
          priority="secondary"
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          data-testid="job-details-edit-form.component-123409"
          priority="primary"
          size="small"
          type="button"
          onClick={triggerFormSubmit}
          loading={isLoading}
          disabled={isLoading}
        >
          Save
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
