import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Select from '@atoms/Select/Select'
import { AbsoluteSpinner } from '@atoms/Spinner/AbsoluteSpinner'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import EmployerBurden from '@components/employer-burden/employer-burden'
import { AP_TYPE, EOR_TYPES } from '@core/constants'
import { useApp } from '@core/context'
import { infoIcon } from '@core/icons/icons'
import { setCurrencyChangeEvent } from '@core/utils'
import { CostSimulation } from '@features/cost-simulation/cost-simulation.component'
import { checkAnnualSalary } from '@services/contract.service'
import { fetchCountriesIdBurden, fetchCountryDetailById, getCountryPartners } from '@services/countries.service'
import { calculateTaxes } from '@services/employer-cost.service'
import { DotWave } from '@uiball/loaders'
import { omit } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Button as BSButton, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean, useDebounceValue } from 'usehooks-ts'
import { v4 } from 'uuid'

import { AdditionalCompensationModal } from '../additional-compensation-modal/additional-compensation-modal.component'
import { AdditionalPaymentsTable } from '../additional-payments-table/additional-payments-table.component'
import { AllowanceModal } from '../allowance-modal/allowance-modal.component'
import { parseAdditionalPayments, parseSigningBonus } from '../create-employee.utils'

const ButtonLink = styled(BSButton)`
  color: #04c53a;
  font-size: 14px;
`

const BurdenText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #878787;
  line-height: 20px;
`

export default ({ working_country, onNext, draft, age, contractType, region, occupation, residencyType }) => {
  const [disableCurrencySelect, setDisableCurrencySelect] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(null)
  const allowanceModalOpen = useBoolean(false)
  const additionalCompensationModalOpen = useBoolean(false)

  const { currencies, profile } = useApp()

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    getFieldState,
    watch,
    formState: { errors },
    reset,
    register,
  } = useForm({
    defaultValues: {
      currency: '',
      yearly_gross_salary: '',
      note: '',
      allowances: [],
      additional_compensations: [],
      signingBonus: 0,
    },
  })

  const watchAllowances = watch('allowances')
  const watchAdditionalCompensations = watch('additional_compensations')

  const currencyWatch = watch('currency')
  const salaryWatch = watch('yearly_gross_salary')
  const [debounceSalary, setDebounceSalary] = useDebounceValue(salaryWatch, 1000)
  const showAnnualSalaryWarning = useBoolean(false)

  const {
    data: countryDetail,
    isLoading: isLoadingCountryDetail,
    isSuccess: isSuccessCountryDetail,
  } = useQuery(['countryDetailQuery', working_country], {
    enabled: !!working_country,
    queryFn: () => fetchCountryDetailById(working_country),
    onSuccess: ({ currency, allow_all_currencies }) => {
      setValue('currency', {
        value: currency.id,
        label: currency.name || currency.short_code,
      })
      setValue('signingBonusCurrency', {
        value: currency.id,
        label: currency.name || currency.short_code,
      })
      setDisableCurrencySelect(!allow_all_currencies)
    },
  })

  const burdenQuery = useQuery(['cost_calc', debounceSalary, currencyWatch], {
    enabled: !!debounceSalary && !!currencyWatch && profile?.eor_type === EOR_TYPES.SAAS,
    keepPreviousData: true,
    queryFn: () =>
      fetchCountriesIdBurden({
        countryId: working_country,
        currency: currencyWatch?.value,
        yearly_salary: debounceSalary,
        company: profile?.id,
      }),
  })

  const calculateTaxesQuery = useQuery(
    [calculateTaxes.key, debounceSalary, currencyWatch, age, contractType, region, occupation, residencyType],
    {
      enabled: !!debounceSalary && !!currencyWatch,
      keepPreviousData: true,
      queryFn: () =>
        calculateTaxes.fetch({
          country: working_country,
          currency: currencyWatch?.value,
          annual_gross_salary: debounceSalary,
          age,
          contract_type: contractType,
          regions: region ? [region] : [],
          occupations: occupation ? [occupation] : [],
          residency_type: residencyType,
          residency: residencyType === 'resident' ? 'local' : 'non_resident',
        }),
    }
  )

  useQuery(['check-annual-salary', debounceSalary, currencyWatch], {
    queryFn: () =>
      checkAnnualSalary({
        currency_short_code: currencies.find(({ id }) => currencyWatch.value === id)?.short_code,
        amount: debounceSalary,
      }),
    onSuccess: () => {
      showAnnualSalaryWarning.setFalse()
    },
    onError: () => {
      showAnnualSalaryWarning.setTrue()
    },
    enabled: !!debounceSalary && !!currencyWatch,
  })

  useEffect(() => {
    if (draft?.compensation) {
      reset({
        currency: draft.compensation.currency,
        yearly_gross_salary: draft.compensation.yearly_gross_salary.toString(),
      })
    }
  }, [draft])

  const submit = (formValues) => {
    onNext({
      compensation: {
        currency: formValues.currency.value,
        yearly_gross_salary: Number(formValues.yearly_gross_salary),
        note: formValues.note,
        signing_bonus: parseSigningBonus(formValues.signingBonus, formValues.signingBonusCurrency),
        allowances: parseAdditionalPayments(formValues.allowances, AP_TYPE.ALLOWANCE),
        additional_compensations: parseAdditionalPayments(formValues.additional_compensations, AP_TYPE.OTHER),
      },
    })
  }

  const calculateAmountPerYear = (salary, divider) => {
    return Number(Number(salary) / divider).toFixed(2)
  }

  // Allowances handlers
  const handleAllowanceModalClose = () => {
    allowanceModalOpen.setFalse()
    setSelectedIndex(null)
  }

  const handleAllowanceDelete = (index) => {
    const allowances = getValues('allowances')
    setValue('allowances', [...allowances.slice(0, index), ...allowances.slice(index + 1)])
  }

  const handleAllowanceEdit = (index) => {
    allowanceModalOpen.setTrue()
    setSelectedIndex(index)
  }

  const handleAllowanceAddSubmit = (data) => {
    setValue('allowances', [...getValues('allowances'), data])
    allowanceModalOpen.setFalse()
  }

  const handleAllowanceEditSubmit = (data) => {
    const allowances = getValues('allowances')
    allowances[selectedIndex] = {
      ...data,
    }
    setValue('allowances', allowances)
    setSelectedIndex(null)
    allowanceModalOpen.setFalse()
  }

  // Additional compensations handlers
  const handleCompensationModalClose = () => {
    additionalCompensationModalOpen.setFalse()
    setSelectedIndex(null)
  }

  const handleCompensationDelete = (index) => {
    const additionalCompensations = getValues('additional_compensations')
    setValue('additional_compensations', [
      ...additionalCompensations.slice(0, index),
      ...additionalCompensations.slice(index + 1),
    ])
  }

  const handleCompensationEdit = (index) => {
    additionalCompensationModalOpen.setTrue()
    setSelectedIndex(index)
  }

  const handleCompensationAddSubmit = (data) => {
    setValue('additional_compensations', [...getValues('additional_compensations'), data])
    additionalCompensationModalOpen.setFalse()
  }

  const handleCompensationEditSubmit = (data) => {
    const additionalCompensations = getValues('additional_compensations')
    additionalCompensations[selectedIndex] = {
      ...data,
    }
    setValue('additional_compensations', additionalCompensations)
    setSelectedIndex(null)
    additionalCompensationModalOpen.setFalse()
  }

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className="d-flex flex-column h-100 employees-page__form">
        <section
          className=" flex-grow-1 d-flex flex-column justify-content-center align-items-center"
          style={{ gap: 16 }}
        >
          <Typography as="h3" className="w-100 heading_semibold__20 color_text_300 mt-4">
            Salary
          </Typography>

          <div className="w-100">
            <Controller
              control={control}
              name="currency"
              rules={{ required: 'Currency is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="CompensationForm-8B82B4"
                    {...field}
                    loading={isLoadingCountryDetail}
                    label="Currency"
                    addText="This is the currency of the country of hire"
                    isDisabled={disableCurrencySelect}
                    options={currencies.map((currency) => ({
                      value: currency.id,
                      label: currency.name,
                    }))}
                  />
                )
              }}
            />
            {errors.currency && (
              <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>
            )}
          </div>

          <div className="w-100">
            <Controller
              control={control}
              rules={{ required: 'Year salary is required' }}
              name="yearly_gross_salary"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  addText="Before taxes, benefits, and other payroll deductions"
                  label="Annual gross salary"
                  placeholder="0.00"
                  step="0.01"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />

            {errors.yearly_gross_salary && (
              <Typography className="text_regular__14 color_red">{errors.yearly_gross_salary.message}</Typography>
            )}
          </div>

          {calculateTaxesQuery.isFetching ? (
            <div className="d-flex w-100 align-items-center justify-content-center p-5">
              <DotWave size={40} speed={1} color="black" />
            </div>
          ) : (
            <>
              {isSuccessCountryDetail && salaryWatch && (
                <>
                  <div className="w-100">
                    <Typography className="text_medium__14 mb-1">
                      Number of payments per year: {countryDetail.yearly_salary_divider}
                    </Typography>
                  </div>

                  <div className="w-100">
                    <Typography className="text_medium__14 mb-1">
                      Amount per payment: {calculateAmountPerYear(salaryWatch, countryDetail.yearly_salary_divider)}
                    </Typography>
                  </div>
                </>
              )}
              {calculateTaxesQuery.data && calculateTaxesQuery.data.can_be_used_for_payroll && (
                <CostSimulation calculations={calculateTaxesQuery.data.calculations} />
              )}
            </>
          )}

          {profile.eor_type === EOR_TYPES.SAAS && (
            <BurdenText>
              If employee is entitled to a performance based bonus, kindly indicate it in additional benefits box.
            </BurdenText>
          )}

          {profile.eor_type === EOR_TYPES.SAAS && (
            <AbsoluteSpinner isFetching={burdenQuery.isFetching}>
              <EmployerBurden currency={currencyWatch?.label} employeeBurden={omit(burdenQuery.data, 'is_active')} />
            </AbsoluteSpinner>
          )}

          {showAnnualSalaryWarning.value && (
            <NotificationBlock text="This salary is unusually low - please ensure you have entered the correct annual salary amount in the field above before continuing" />
          )}

          {/* <Typography as="h3" className="w-100 heading_semibold__20 color_text_300 mt-4">
            Signing bonus{' '}
            <OverlayTrigger
              overlay={
                <Tooltip>Signing bonus will be included in the employment agreement and could be taxable</Tooltip>
              }
            >
              <span>
                <Icon icon={infoIcon} style={{ fill: '#878787', width: '18px' }} />
              </span>
            </OverlayTrigger>
          </Typography> */}

          {/* <div className="w-100">
            <Controller
              control={control}
              name="signingBonusCurrency"
              rules={{ required: 'Currency is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="CompensationForm-SigningBonusCurrency"
                    {...field}
                    loading={isLoadingCountryDetail}
                    label="Currency"
                    addText="You can change the currency to facilitate your input, but the invoice currency will stay the same as agreed in your RemoFirst MSA"
                    options={currencies.map((currency) => ({
                      value: currency.id,
                      label: currency.name,
                    }))}
                  />
                )
              }}
            />
            {errors.signingBonusCurrency && (
              <Typography className="text_regular__14 color_red">{errors.signingBonusCurrency.message}</Typography>
            )}
          </div>

          <div className="w-100">
            <Controller
              control={control}
              name="signingBonus"
              rules={{
                required: 'Signing bonus is required',
                validate: {
                  minlength: (v) =>
                    /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) || 'Only 2 digits allowed after decimal point',
                },
              }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  addText="One time payment part of the employee’s first payroll; leave 0.00 if it’s not applicable"
                  label="Amount"
                  placeholder="0.00"
                  step="0.01"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />
            {errors.signingBonus && (
              <Typography className="text_regular__14 color_red">{errors.signingBonus.message}</Typography>
            )}
          </div>

          <Typography as="h3" className="w-100 heading_semibold__20 color_text_300 mt-4">
            Allowance{' '}
            <OverlayTrigger overlay={<Tooltip>Allowance could be taxable</Tooltip>}>
              <span>
                <Icon icon={infoIcon} style={{ fill: '#878787', width: '18px' }} />
              </span>
            </OverlayTrigger>
          </Typography>

          <div className="w-100">
            <AdditionalPaymentsTable
              data={watchAllowances}
              onEdit={handleAllowanceEdit}
              onDelete={handleAllowanceDelete}
            />
            <ButtonLink onClick={allowanceModalOpen.setTrue} variant="link">
              + Add alowance
            </ButtonLink>
          </div>

          <Typography as="h3" className="w-100 heading_semibold__20 color_text_300 mt-4">
            Additional compensation{' '}
            <OverlayTrigger overlay={<Tooltip>Additional compensation could be taxable</Tooltip>}>
              <span>
                <Icon icon={infoIcon} style={{ fill: '#878787', width: '18px' }} />
              </span>
            </OverlayTrigger>
          </Typography>

          <div className="w-100">
            <AdditionalPaymentsTable
              data={watchAdditionalCompensations}
              onEdit={handleCompensationEdit}
              onDelete={handleCompensationDelete}
            />
            <ButtonLink onClick={additionalCompensationModalOpen.setTrue} variant="link">
              + Add additional compensation
            </ButtonLink>
          </div> */}

          <Typography as="h3" className="w-100 heading_semibold__20 color_text_300 mt-4">
            Additional notes on employment terms
          </Typography>

          <div className="w-100">
            <TextArea
              {...register('note')}
              label="Notes"
              placeholder="Please add any specific information that you want our team to take into consideration while drafting the employment agreement"
            />
          </div>
        </section>

        <div className="pb-5 align-self-center">
          <Button data-testid="CompensationForm-68F902" type="submit">
            Continue
          </Button>
        </div>
      </form>
      {allowanceModalOpen.value && (
        <AllowanceModal
          selectedAllowance={getValues('allowances')[selectedIndex]}
          onAdd={handleAllowanceAddSubmit}
          onEdit={handleAllowanceEditSubmit}
          onClose={handleAllowanceModalClose}
          countryCurrency={countryDetail.currency}
        />
      )}
      {additionalCompensationModalOpen.value && (
        <AdditionalCompensationModal
          selectedCompensation={getValues('additional_compensations')[selectedIndex]}
          onAdd={handleCompensationAddSubmit}
          onEdit={handleCompensationEditSubmit}
          onClose={handleCompensationModalClose}
          countryCurrency={countryDetail.currency}
        />
      )}
    </>
  )
}
