import { EMPLOYEE_STATES, PaymentValue, PRONOUNS, RESIDENCY_TYPES } from '@core/constants'
import { capitalizeFirstLetter, formatDate, getCurrency, SplitNumber } from '@core/utils'
import moment from 'moment'

export const fields = [
  {
    title: 'Name',
    key: 'full_name',
    type: 'string',
  },
  {
    title: 'Role',
    key: 'job_position',
    type: 'string',
  },
]

const getContractType = (employee) =>
  employee.contract_type === 'contractor'
    ? [
        {
          label: 'Contract type',
          value: ['Contractor'],
        },
        {
          label: 'Estimated working hours',
          value: [employee.compensation.estimated_working_hours],
          hidden: employee.compensation.contractor_wage_type !== 'hourly',
        },
      ]
    : [
        {
          label: 'Contract type',
          value: ['Full time employee'],
        },
      ]

const getAddress = (worker) => {
  const { profile } = worker
  const address =
    worker.contract_type === 'contractor'
      ? [
          {
            label: 'Billing Address',
            value: [
              profile.address?.living_country?.name || '',
              profile.address?.city,
              profile.address?.street_address,
              profile.address?.postal_code,
              profile.address?.address_line,
            ].filter((v) => !!v),
          },
          {
            label: 'Home Address',
            value: [
              profile.home_address?.living_country?.name || '',
              profile.home_address?.city,
              profile.home_address?.street_address,
              profile.home_address?.postal_code,
              profile.home_address?.address_line,
            ].filter((v) => !!v),
          },
        ]
      : [
          {
            label: 'Home Address',
            value: [
              profile.address?.living_country?.name || '',
              profile.address?.city,
              profile.address?.street_address,
              profile.address?.postal_code,
              profile.address?.address_line,
            ].filter((v) => !!v),
          },
        ]
  return address
}

const getIdEmployee = (employee) =>
  employee
    ? employee.company.title.slice(0, 3).toUpperCase() +
      employee.profile.first_name[0].toUpperCase() +
      employee.profile.last_name[0].toUpperCase() +
      employee.id
    : null

export const getWorkPermit = (employee) =>
  employee.work_permit
    ? [
        {
          label: 'Work permit required',
          value: [employee.profile.needs_work_permit ? 'Yes' : 'No'],
        },
        {
          label: 'Work permit status',
          value: [employee.work_permit ? capitalizeFirstLetter(employee.work_permit.status) : ''],
        },
        {
          label: 'Work permit expiry',
          value: [
            employee.work_permit.expiration_date
              ? moment(employee.work_permit.expiration_date).format('DD MMM YYYY')
              : '-',
          ],
        },
      ]
    : []

export const getResidencyType = (employee) =>
  employee.residency_type
    ? [
        {
          label: 'Residency Type',
          value: [employee.residency_type === RESIDENCY_TYPES.RESIDENT ? 'Resident' : 'Non-Resident'],
        },
      ]
    : []

export const getPersonalInformation = (employee) => [
  {
    label: 'Name',
    value: [
      `${employee.profile.first_name || ''} ${employee.profile.middle_name || ''} ${employee.profile.last_name || ''}`,
    ],
  },
  {
    label: 'Pronouns',
    value: [PRONOUNS[employee.profile.pronouns]],
  },
  {
    label: 'ID',
    value: [getIdEmployee(employee)],
  },
  {
    label: 'Email',
    value: [employee.profile.email],
  },
  {
    label: 'Date of birth',
    value: employee.profile.birth_date ? [moment(employee.profile.birth_date).format('DD MMM YYYY')] : null,
  },
  {
    label: 'Phone number',
    value: [employee.profile.phone_number],
  },
  ...getAddress(employee),
  ...(employee.contract_type !== 'contractor' ? getWorkPermit(employee) : []),
  ...(employee.contract_type !== 'contractor' ? getResidencyType(employee) : []),
]

export const getJobDetails = (employee) => [
  {
    label: 'Job title',
    value: employee.job.position,
  },
  {
    label: 'Scope of work/Job description',
    value: employee.job.position_description,
  },
  {
    label: 'Department',
    value: employee.department,
  },
  {
    label: 'Line manager',
    value: employee.direct_manager?.full_name,
  },
  {
    label: 'Annual gross salary',
    value: `${employee.compensation?.currency?.sign || employee.compensation?.currency?.short_code} ${SplitNumber(
      employee.compensation.yearly_gross_salary
    )}`,
    hidden: employee.contract_type === 'contractor',
  },
  {
    label: 'Country number of salaries (per year)',
    value: employee.working_country.yearly_salary_divider,
    hidden: employee.contract_type === 'contractor',
  },
  {
    label: 'Monthly gross salary',
    value: `${employee.compensation?.currency?.sign || employee.compensation?.currency?.short_code} ${SplitNumber(
      employee.compensation.monthly_salary
    )}`,
    hidden: employee.contract_type === 'contractor',
  },
  ...getContractType(employee),
  {
    label: 'Contract is permanent',
    value: employee.job.is_permanent ? 'Yes' : 'No',
  },
  {
    label: 'Start date',
    value:
      employee.state === EMPLOYEE_STATES.ACTIVE
        ? formatDate(employee.job.starts_at)
        : formatDate(employee.job.requested_starts_at),
  },
  {
    label: 'End date',
    value: employee.job.ends_at ? moment(employee.job.ends_at).format('DD MMM YYYY') : undefined,
  },
]

const getWorkEquipment = (employee) =>
  employee?.benefit?.provides_work_equipment
    ? [
        {
          label: 'Work equipment specification',
          value: employee?.benefit.equipment_specification,
        },
        {
          label: 'Delivery date',
          value: employee?.benefit.delivered_at ? moment(employee.benefit.delivered_at).format('DD MMM YYYY') : null,
        },
      ]
    : []
export const getBenefit = (employee) => [
  {
    label: 'Private health insurance',
    value: employee?.benefit?.provides_health_insurance ? 'Provided' : 'Not provided',
  },
  {
    label: 'Private dental insurance',
    value: employee?.benefit?.provides_dental_insurance ? 'Provided' : 'Not provided',
  },
  {
    label: 'Work equipment',
    value: employee.benefit.provides_work_equipment ? 'Provided' : 'Not provided',
  },
  ...getWorkEquipment(employee),
]

export const getEmergencyContact = (contact) => [
  {
    label: 'Contact name',
    value: contact.name,
  },
  {
    label: 'Relationship',
    value: contact.relationship,
  },
  {
    label: 'Email',
    value: contact.email,
  },
  {
    label: 'Phone number',
    value: contact.phone_number,
  },
]

export const getCompensationInfo = (data) => [
  {
    label: 'Country',
    value: data?.profile?.address?.living_country?.name || '',
  },
  {
    label: 'Contract currency',
    value: data?.compensation?.currency?.short_code || '-',
  },
  {
    label: 'Receiving currency',
    value: data?.compensation?.receiving_currency?.short_code,
  },
  ...(data?.compensation.contractor_wage_type !== 'milestone'
    ? [
        {
          label: 'Contract rate',
          value: `${getCurrency(data.compensation?.currency)}
  ${SplitNumber(data.compensation.contractor_rate)} / ${data.compensation.contractor_wage_type}`,
        },
        {
          label: 'Payment frequency',
          value: PaymentValue[data?.compensation?.contractor_payment_frequency],
        },
      ]
    : []),
]

export const getPaidTimeOff = (timeOff) => [
  {
    label: 'Annual paid time off (vacation) days by contract',
    value: [timeOff.annual_days_by_contract],
    description:
      "This refers to the total number of paid leave days an employee is eligible for in a year. This figure is typically defined by the employment contract and the company's leave policy, and may vary based on factors like the employee's tenure, role, or regional labour laws.",
  },
  {
    label: 'Accrued paid time off days',
    value: [timeOff.accrued_days],
    description:
      'This term refers to the leave days that an employee earns over a period of time, typically accruing each month. For instance, if the total annual entitlement is 12 days, an employee will accrue 1 day of leave per month. Accrued Days Formula = [Months Worked] * [Annual Entitlement Days/12].',
  },
  {
    label: 'Used paid time off (vacation) days',
    value: [timeOff.used_days],
    description:
      "This is the total number of leave days that an employee has already used. It's subtracted from the annual entitlement (or accrued days) to calculate the remaining leave balance.",
  },
  {
    label: 'Available paid time off (vacation) days',
    value: [timeOff.available_days],
    description:
      'This number represents the remaining paid leave days an employee has at their disposal. It is calculated by taking the total accrued days and subtracting taken days.',
  },
]

export const getPaidSickLeaves = (timeOff) => [
  {
    label: 'Annual paid sick leave by contract',
    value: [timeOff.annual_days_by_contract],
  },
  {
    label: 'Used paid sick leave ',
    value: [timeOff.used_days],
  },
  {
    label: 'Available paid sick leave',
    value: [timeOff.available_days],
  },
]

export const getPaidBereavementLeaves = (timeOff) => [
  {
    label: 'Annual paid bereavement leave by contract',
    value: [timeOff.annual_days_by_contract],
  },
  {
    label: 'Used paid bereavement leave ',
    value: [timeOff.used_days],
  },
  {
    label: 'Available paid bereavement leave',
    value: [timeOff.available_days],
  },
]

export const getPaidParental = (timeOff) => [
  {
    label: 'Annual paid parental leave by contract',
    value: [timeOff.annual_days_by_contract],
  },
  {
    label: 'Used paid parental leave',
    value: [timeOff.used_days],
  },
  {
    label: 'Available paid parental leave',
    value: [timeOff.available_days],
  },
]
