import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import RadioButton from '@atoms/RadioButton/RadioButton'
import { Tooltip } from '@atoms/Tooltip/tooltip.component'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import { PAYMENT_FREQUENCY } from '@core/constants'
import { useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import { getContractsAvailableCurrency } from '@services/contract.service'
import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { v4 } from 'uuid'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  .modal-header {
    margin-bottom: 0;
  }
  .modal-content {
    padding: 0;
    width: 600px;
  }
  .modal-body {
    position: relative;
  }
`

const FormControl = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`

export const CompensationForm = ({ title, data, onSubmit, onClose, isLoading, workingCountryId }) => {
  const { countries, currencies } = useApp()

  const formRef = useRef(null)
  const {
    register,
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm()
  const isFixed = watch('contractTerm') === 'fixed'
  const rateType = watch('rateType')
  const frequency = watch('paymentFrequency')

  const country = countries.find((item) => item.id === workingCountryId)

  const { data: availableCurrencyData } = useQuery({
    queryKey: ['availableCurrencies', workingCountryId],
    queryFn: () => getContractsAvailableCurrency(workingCountryId),
    enabled: !!workingCountryId,
  })
  const getContractCurrencyOptions = () => {
    if (country?.nium_supported && availableCurrencyData?.available_currencies.length) {
      return availableCurrencyData.available_currencies.map((c) => ({
        text: `${c.short_code}${c.is_local ? ' (local currency)' : ''}`,
        value: c.id,
      }))
    }
    const defaultCurrencyShortCode = 'USD'
    const localCurrency = country?.currency
    const usdCurrency = currencies.find((item) => item.short_code === defaultCurrencyShortCode)

    return usdCurrency?.id !== localCurrency?.id
      ? [
          {
            id: 1,
            text: defaultCurrencyShortCode,
            value: usdCurrency?.id,
          },
          {
            id: 2,
            text: `${localCurrency?.short_code || ''} (local currency)`,
            value: localCurrency?.id,
          },
        ]
      : [
          {
            id: 1,
            text: defaultCurrencyShortCode,
            value: usdCurrency?.id,
          },
        ]
  }

  useEffect(() => {
    reset({
      ...data,
      startDate: new Date(data.startDate),
      endDate: data.endDate !== '-' ? new Date(data.endDate) : undefined,
    })
  }, [data])

  const triggerFormSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  return (
    <StyledModal show onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>
      <Modal.Body>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <Controller
              control={control}
              name="contractTerm"
              render={({ field }) => (
                <RadioButton
                  {...field}
                  label="Contract term"
                  options={[
                    {
                      id: 'indefinite',
                      text: 'Indefinite',
                      value: 'indefinite',
                    },
                    {
                      id: 'fixed',
                      text: 'Fixed term',
                      value: 'fixed',
                    },
                  ]}
                />
              )}
            />
          </FormControl>
          <div className="d-flex gap-3 w-100 remo-form-input">
            <FormControl>
              <Controller
                control={control}
                name="startDate"
                rules={{ required: 'Start date is required' }}
                render={({ field }) => {
                  return <DateInput {...field} label="Start date" />
                }}
              />
              {errors.startDate && (
                <Typography className="text_regular__14 color_red">{errors.startDate.message}</Typography>
              )}
            </FormControl>
            {isFixed && (
              <FormControl>
                <Controller
                  control={control}
                  rules={{ required: 'End date is required' }}
                  name="endDate"
                  render={({ field }) => {
                    return <DateInput {...field} label="End date" />
                  }}
                />
                {errors.endDate && (
                  <Typography className="text_regular__14 color_red">{errors.endDate.message}</Typography>
                )}
              </FormControl>
            )}
          </div>
          <FormControl>
            <Controller
              control={control}
              name="rateType"
              rules={{ required: 'Wage type is required' }}
              render={({ field }) => (
                <RadioButton
                  {...field}
                  label="Rate type"
                  options={[
                    {
                      id: 'monthly2',
                      text: 'Monthly',
                      value: 'monthly',
                    },
                    {
                      id: 'daily',
                      text: 'Daily',
                      value: 'daily',
                    },
                    {
                      id: 'hourly',
                      text: 'Hourly',
                      value: 'hourly',
                    },
                  ]}
                />
              )}
            />
            {errors.rateType && (
              <Typography className="text_regular__14 color_red">{errors.rateType.message}</Typography>
            )}
          </FormControl>

          <FormControl>
            <Controller
              control={control}
              name="contractCurrencyId"
              rules={{ required: 'Currency is required' }}
              render={({ field }) => (
                <RadioButton {...field} label="Contract currency" options={getContractCurrencyOptions()} />
              )}
            />

            {errors.contractCurrencyId && (
              <Typography className="text_regular__14 color_red">{errors.contractCurrencyId.message}</Typography>
            )}
          </FormControl>

          <InfoMessage
            message="This is the currency that you have agreed with a contractor. You
              will be invoiced in your company's default currency. The
              contractor will be paid either in USD or local currency."
          />

          <FormControl>
            <Controller
              control={control}
              name="contractorRate"
              rules={{ required: 'Contractor rate is required' }}
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  step=".01"
                  label="Contractor rate"
                  placeholder="00.00"
                  onChange={setCurrencyChangeEvent(field.onChange)}
                />
              )}
            />

            {errors.contractorRate && (
              <Typography className="text_regular__14 color_red">{errors.contractorRate.message}</Typography>
            )}
          </FormControl>

          <FormControl>
            <Controller
              control={control}
              name="paymentFrequency"
              rules={{ required: 'Payment frequency is required' }}
              render={({ field }) => {
                return (
                  <RadioButton
                    {...field}
                    label={
                      <div className="d-flex gap-2">
                        Payment frequency
                        <Tooltip
                          id={v4()}
                          clickable
                          style={{ width: '500px' }}
                          content={
                            <div>
                              Payment periods are automatically calculated based on the selection in this column:
                              <ul>
                                <li>Weekly - payments will be automatically created each week;</li>
                                <li>Semi-monthly - payments will be automatically created twice a month;</li>
                                <li>Monthly - payments will be automatically created once a month.</li>
                              </ul>
                            </div>
                          }
                        />
                      </div>
                    }
                    options={[
                      {
                        id: PAYMENT_FREQUENCY[0].value,
                        text: PAYMENT_FREQUENCY[0].label,
                        value: PAYMENT_FREQUENCY[0].value,
                      },
                      {
                        id: PAYMENT_FREQUENCY[1].value,
                        text: PAYMENT_FREQUENCY[1].label,
                        value: PAYMENT_FREQUENCY[1].value,
                      },
                      {
                        id: PAYMENT_FREQUENCY[2].value,
                        text: PAYMENT_FREQUENCY[2].label,
                        value: PAYMENT_FREQUENCY[2].value,
                      },
                    ]}
                  />
                )
              }}
            />
            {errors.paymentFrequency && (
              <Typography className="text_regular__14 color_red">{errors.paymentFrequency.message}</Typography>
            )}
          </FormControl>

          {rateType === 'hourly' && (
            <FormControl>
              <Input
                data-testid="compensation-edit-form.component-63FDDE"
                id={`input_${frequency?.value}`}
                {...register('estimated_working_hours', {
                  required: 'Number of hours is required',
                })}
                type="number"
                label={`Number of hours ${
                  frequency
                    ? `(estimated ${PAYMENT_FREQUENCY.find((f) => f.value === frequency).label.toLowerCase()})`
                    : ''
                }`}
                placeholder="Estimated working hours"
                tooltip={
                  <Tooltip
                    id={v4()}
                    clickable
                    style={{ width: '500px' }}
                    content="Enter the estimated number of hours the contractor will work over a chosen period"
                  />
                }
              />
              {errors.estimated_working_hours && (
                <Typography className="text_regular__14 color_red">{errors.estimated_working_hours.message}</Typography>
              )}
            </FormControl>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="compensation-edit-form.component-A0E8CD"
          type="button"
          priority="secondary"
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          data-testid="compensation-edit-form.component-883958"
          priority="primary"
          size="small"
          type="button"
          onClick={triggerFormSubmit}
          loading={isLoading}
          disabled={isLoading}
        >
          Save
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
