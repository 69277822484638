import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { AttachmentDropzone } from '@components/attachment/attachment-dropzone/attachment-dropzone'
import { InfoMessage } from '@components/info-message/info-message.component'
import { fetchPostFile } from '@services/app.service'
import { patchContractById } from '@store/contracts'
import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { ReactComponent as ContractUploadSuccessIcon } from '../../../../../assets/icons/contract-upload-success-icon.svg'

const imageFileExtensions = ['image/jpeg', 'image/png', '.jpg', '.jpeg', '.png']

export const UploadContract = ({ onClose, contractor }) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFileUuid, setSelectedFileUuid] = useState(null)
  const successModal = useBoolean(false)

  const temporaryFilesMutation = useMutation({
    mutationFn: fetchPostFile,
  })

  const contractUploadMutation = useMutation({
    mutationFn: (payload) => patchContractById(payload.id, payload.data),
    onSuccess: successModal.setTrue,
  })

  const handleDrop = ([file]) => {
    temporaryFilesMutation
      .mutateAsync(file)
      .then(({ file_uuid }) => setSelectedFileUuid(file_uuid))
      .then(() => setSelectedFile(file))
  }
  const handleRemoveFile = () => setSelectedFile(null)

  const onSave = () => {
    contractUploadMutation.mutate({
      id: contractor.id,
      data: {
        compensation: {
          ...contractor.compensation,
          receiving_currency: contractor.compensation.receiving_currency.id,
          currency: contractor.compensation.currency.id,
          working_country_currency: contractor.compensation.working_country_currency.id,
        },
        temporary_file: selectedFileUuid,
      },
    })
  }

  if (successModal.value) {
    return (
      <div className="d-flex flex-column align-items-center">
        <ContractUploadSuccessIcon />
        <Typography className="heading_semibold__16 mb-2">Contract uploaded and sent!</Typography>
        <Typography style={{ color: '#6d6d6c', marginBottom: 4 }}>
          Track the progress of your contract from your agreements section.
        </Typography>
        <Button data-testid="upload-contract-AA20CB" className="mx-auto mt-5" onClick={onClose}>
          Complete setup
        </Button>
      </div>
    )
  }

  return (
    <div>
      <Typography className="heading_semibold__16 mb-2">Upload contract</Typography>
      <Typography style={{ color: '#6d6d6c', marginBottom: 4 }}>
        Please upload contract that was already signed with a contractor
      </Typography>
      <AttachmentDropzone
        multiple={false}
        accept={imageFileExtensions.join(',')}
        file={selectedFile}
        isLoading={temporaryFilesMutation.isLoading || contractUploadMutation.isLoading}
        onDrop={handleDrop}
        onDelete={handleRemoveFile}
      >
        Permitted files .jpeg, .png. .pdf no more than 50 MB
      </AttachmentDropzone>
      <InfoMessage message={<span>Remofirst holds no legal responsibility for the uploaded contracts.</span>} />
      <div className="pb-5 align-self-center d-flex">
        {selectedFile ? (
          <Button data-testid="upload-contract-69505C" className="m-auto" onClick={onSave}>
            Complete setup
          </Button>
        ) : (
          <Button data-testid="upload-contract-534774" className="m-auto" priority="secondary" onClick={onClose}>
            Add contract later
          </Button>
        )}
      </div>
    </div>
  )
}
