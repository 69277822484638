import { EMPLOYEE_STATES } from '@core/constants'
import { formatDate, SplitNumber } from '@core/utils'

export const ContractorWageType = {
  monthly: 'm',
  hourly: 'h',
  daily: 'd',
  milestone: 'milestone',
}

export const employeeTableParse = (contracts, page) => {
  const displaySalary = (contract) => {
    const isContractor = contract.contract_type === 'contractor'
    const isMilestoneBased = contract.compensation.contractor_wage_type === 'milestone'

    if (isMilestoneBased) {
      return 'Milestone'
    }

    return isContractor
      ? `${contract.currency?.sign || contract.currency?.short_code} ${SplitNumber(contract.contractor_rate)}/${ContractorWageType[contract.contractor_wage_type]}`
      : `${contract.currency.sign || contract.currency?.short_code} ${SplitNumber(contract.yearly_gross_salary)}/y`
  }

  return contracts.map((contract, index) => ({
    id: contract.id,
    number: (page - 1) * 10 + index + 1,
    full_name: contract.full_name,
    avatar: contract.avatar,
    customer: contract.company.name,
    department: contract.department,
    job_position: contract.job_position,
    type: contract.contract_type === 'contractor' ? 'Contractor' : 'FTE',
    salary: displaySalary(contract),
    receiving_currency: contract.compensation?.receiving_currency?.name || '-',
    status: contract.state,
    location: contract.living_country?.name || '',
    start_at:
      contract.state === EMPLOYEE_STATES.ACTIVE
        ? formatDate(contract.starts_at)
        : formatDate(contract.requested_starts_at),
    ...contract,
  }))
}
