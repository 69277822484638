import { useEffect } from 'react'

export const useKompassify = (profile) => {
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (profile?.id) {
      const handleKompassifyReady = (event) => {
        if (event.data.TYPE === 'KOMPASSIFY_BOOT_LOADER_IS_READY') {
          window.kommpassifyIdUser(profile?.id)
          window.kompassifyTemplating.addTemplateVariable('client', `${profile?.name}`)
          window.kompassifySegmentation.addUserToSegment('Client')
        }
      }
      window.addEventListener('message', handleKompassifyReady)
      return () => {
        window.removeEventListener('message', handleKompassifyReady)
      }
    }
  }, [profile?.id, profile?.name])
}
