import './payment-method-add.modal.scss'

import Button from '@atoms/Button/Button'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useNotification'
import { primaryPaymentMethods } from '@services/stripe.service'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useMutation } from 'react-query'

export const PaymentMethodAddModal = ({
  refetch,
  paymentMethodsList,
  modalState,
  modalAutoPayment,
  newPaymentMethod,
  outstandInvoice,
  modalInvoiceState,
}) => {
  const { successAlert } = useToast()
  const { profile } = useApp()
  const [defaultPayment, setDefaultMethod] = useState()

  const currentDefaultPayment = paymentMethodsList.find((f) => f.payment_method.metadata?.default === 'True')
  const newPayment = paymentMethodsList.find((f) => f.id === newPaymentMethod?.id)

  const setDefaultPaymentMutation = useMutation('SetPaymentMethod', {
    mutationFn: (payload) => primaryPaymentMethods(payload),
    onSuccess: () => {
      refetch()

      successAlert('Successfully updated')
      if (outstandInvoice && profile.enabled_automatic_pay_for_fte) {
        modalInvoiceState.setTrue()
      }
    },
  })
  useEffect(() => {
    setDefaultMethod(currentDefaultPayment?.payment_method.id)
  }, [paymentMethodsList])

  const onSave = () => {
    if (currentDefaultPayment?.payment_method?.id !== defaultPayment) {
      setDefaultPaymentMutation.mutate(defaultPayment)
      modalState.setFalse()
    } else if (paymentMethodsList[0]) {
      if (!profile.enabled_automatic_pay_for_fte) {
        modalAutoPayment.setTrue()
      }
      setDefaultPaymentMutation.mutate(paymentMethodsList[0].payment_method?.id)
      modalState.setFalse()
    }
  }
  return (
    <Modal show={modalState.value} onHide={onSave} centered animation={false}>
      <Modal.Header closeButton className="mb-0">
        <Modal.Title>
          <Typography className="heading_semibold__24">Default payment method</Typography>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="success mt-4">
          <Typography className="heading_semibold__16">Success</Typography>
          <Typography>Payment method added.</Typography>
        </div>

        {paymentMethodsList.length > 1 ? (
          <>
            <Typography className="text_regular__14 mt-3 color_grey">
              Your{' '}
              {`{ ACH Direct Debit, Swift ending in ${
                newPayment?.payment_method?.us_bank_account?.last4 || ''
              } in USD }`}{' '}
              has been successfully added and you have multiple payment methods available now.
            </Typography>
            <Typography className="text_regular__14 mt-3 color_grey">
              Please choose your default payment method:
            </Typography>

            <RadioButton
              layout="vertical"
              options={paymentMethodsList?.map((item) => ({
                id: item.payment_method.id,
                text: (
                  <div className="mt-2 ml-2">
                    <Typography>ACH Direct Debit | USD</Typography>
                    <Typography className="color_grey">
                      {`Visa credit card ending in ${item?.payment_method?.us_bank_account.last4}`}
                    </Typography>
                  </div>
                ),
                value: item.payment_method.id,
              }))}
              value={defaultPayment}
              onChange={(e) => setDefaultMethod(e.target.value)}
            />
          </>
        ) : (
          <>
            <Typography className="text_regular__14 mt-3 color_grey">
              Your {`ACH Direct Debit, Swift ending in ${newPayment?.payment_method?.us_bank_account.last4} in USD `}{' '}
              has been successfully added and it will be your default payment method.
            </Typography>
            <Typography className="text_regular__14 mt-3 color_grey">
              Future payments of approved payrolls will be automatically settled using this method.
            </Typography>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="payment-method-add.modal-C6C8CD"
          type="submit"
          size="small"
          priority="primary"
          onClick={onSave}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
