import './style.scss'

import Icon from '@atoms/Icon/Icon'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { CONTRACT_TYPE } from '@core/constants'
import { keyboardArrowLeft } from '@core/icons/icons'
import Compilance from '@pages/employeeDetailPage/libs/Compilance/Compilance'
import { fetchCountryOccupations, fetchCountryRegions } from '@services/countries.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { Link, useMatch, useParams } from 'react-router-dom'
import { getEmployeeDetails } from 'src/services/employee.service'

import Agreements from './libs/Agreements/agreements'
import Benefit from './libs/Benefit/Benefit'
import DocumentDetailEmployee from './libs/DocumentDetailEmployee/DocumentDetailEmployee'
import EmergencyContact from './libs/EmergencyContact/EmergencyContact'
import { InvoiceDetailEmployee } from './libs/InvoiceDetailEmployee/InvoiceDetailEmployee'
import JobDetails from './libs/JobDetails/JobDetails'
import { JobDetailsFte } from './libs/JobDetailsFte/JobDetailsFte'
import { MilestonesTab } from './libs/MilestonesTab/MilestonesTab'
import { PaymentDetailsTab } from './libs/PaymentDetailsTab/PaymentDetailsTab'
import Personal from './libs/Personal/Personal'
import TimeOffEmployee from './libs/TimeOff/TimeOffEmployee'

export default function EmployeeDetailPage() {
  const params = useParams()
  const [employee, setEmployee] = useState()
  const [occupations, setOccupations] = useState([])
  const [regions, setRegions] = useState([])

  const { isLoading: detailEmployeeLoading, refetch } = useQuery('detailEmployee', {
    queryFn: () => getEmployeeDetails(params.id),
    enabled: !!params.id,
    onSuccess: (v) => {
      setEmployee(v)
    },
  })

  const occupationsData = useQuery(
    'getCountryOccupations',
    () => fetchCountryOccupations(employee.working_country.id),
    {
      enabled: !!employee?.working_country?.id,
      onSuccess: (data) => {
        setOccupations(data.results)
      },
    }
  )

  const regionsData = useQuery('getCountryRegions', () => fetchCountryRegions(employee.working_country.id), {
    enabled: !!employee?.working_country?.id,
    onSuccess: (data) => {
      setRegions(data.results)
    },
  })

  return (
    <div className="employee-detail-page h-100">
      {detailEmployeeLoading ? (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <DotWave size={48} speed={1} color="black" />
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center mb-4">
            <Link to="/pages/team">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  border: '1px solid #CCCCCC',
                  boxSizing: 'border-box',
                  borderRadius: '8px',
                  height: 40,
                  width: 40,
                }}
              >
                <Icon icon={keyboardArrowLeft} style={{ cursor: 'pointer', transform: 'rotate(180deg)' }} />
              </div>
            </Link>
            <Typography className="heading_semibold__24 ml-3">
              {employee?.profile.first_name} {employee?.profile.last_name}
            </Typography>
          </div>
          <Tabs>
            <Tab tabId={0} title="Personal">
              {employee && <Personal employee={employee} refetch={refetch} setEmployee={setEmployee} />}
            </Tab>
            <Tab tabId={1} title="Job details">
              {employee?.contract_type === CONTRACT_TYPE.FULL_TIME_EMPLOYEE && (
                <JobDetailsFte
                  employee={employee}
                  setEmployee={setEmployee}
                  occupations={occupations}
                  regions={regions}
                />
              )}
              {employee?.contract_type === CONTRACT_TYPE.CONTRACTOR && (
                <JobDetails employee={employee} setEmployee={setEmployee} occupations={occupations} />
              )}
            </Tab>
            <Tab tabId={2} title="Payment Details" hidden={employee && employee.contract_type === 'full_time_employee'}>
              {employee && <PaymentDetailsTab employee={employee} setEmployee={setEmployee} refetch={refetch} />}
            </Tab>
            <Tab
              tabId={3}
              title="Milestones"
              hidden={
                employee?.contract_type === 'full_time_employee' ||
                employee?.compensation.contractor_wage_type !== 'milestone'
              }
            >
              {employee && <MilestonesTab employee={employee} />}
            </Tab>
            <Tab tabId={4} title="Benefits" hidden={employee && employee.contract_type === 'contractor'}>
              {employee && <Benefit employee={employee} setEmployee={setEmployee} refetch={refetch} />}
            </Tab>
            <Tab
              tabId={5}
              title="Emergency contact"
              hidden={employee && (employee.contract_type === 'contractor' || employee.state !== 'active')}
            >
              {employee && <EmergencyContact employee={employee} />}
            </Tab>
            <Tab tabId={6} title="Documents">
              {employee && <DocumentDetailEmployee id={employee.id} employee={employee} />}
            </Tab>
            <Tab tabId={7} hidden={employee && employee.contract_type === 'full_time_employee'} title="Invoices">
              {employee && <InvoiceDetailEmployee id={employee.id} />}
            </Tab>
            <Tab
              tabId={8}
              title="Time-off"
              hidden={employee && employee.compensation.contractor_wage_type !== 'monthly'}
            >
              {employee && <TimeOffEmployee employee={employee} />}
            </Tab>
            <Tab tabId={9} title="Agreements">
              {employee && <Agreements employee={employee} />}
            </Tab>
            <Tab
              tabId={10}
              title="Onboarding checklist"
              hidden={employee && employee.contract_type === 'full_time_employee'}
            >
              {employee && <Compilance id={employee.id} />}
            </Tab>
          </Tabs>
        </>
      )}
    </div>
  )
}
