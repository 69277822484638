import Select from '@atoms/Select/Select'
import { Tooltip } from '@atoms/Tooltip/tooltip.component'
import Typography from '@atoms/Typography/Typography'
import { SwiftFeePayRoleTooltip } from '@components/swift-fee-pay-role-tooltip/swift-fee-pay-role-tooltip.component'
import { fieldNameMap } from '@core/constants'
import { useApp } from '@core/context'
import * as icons from '@core/icons/icons'
import { SwiftFeePayrollLabel } from '@pages/profilePage/mock'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'
import { v4 } from 'uuid'

const FormControl = styled.div`
  margin-bottom: 1rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`

export const PaymentInformationForm = ({ formId, onSubmit }) => {
  const { profile, currencies } = useApp()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currency: profile?.invoice_settings?.contractor_default_currency?.id,
    },
  })

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)}>
      <Typography className="heading_semibold__20 mb-3">Payment details</Typography>
      <FormControl>
        <Controller
          control={control}
          name="currency"
          rules={{ required: 'Currency is required' }}
          render={({ field }) => {
            return (
              <Select
                data-testid="payment-information-form.component-4F97DA"
                {...field}
                label="Default currency"
                addText="Invoices from Remofirst to your company will be generated in the following currency"
                options={currencies.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
                isDisabled
                isRequired
              />
            )
          }}
        />
        {errors.currency && <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>}
      </FormControl>

      <Typography className="heading_semibold__20 mb-3">Contractor payments</Typography>
      <FormControl>
        <Controller
          control={control}
          name="swiftFeePayRole"
          rules={{ required: `SWIFT fee is required` }}
          render={({ field }) => (
            <div className="d-flex align-items-center gap-2">
              <Select
                data-testid="payment-information-form.component-DABF6F"
                {...field}
                label="International wire transfer (SWIFT) Fee"
                addText="Please indicate who should be responsible for covering the SWIFT fees"
                placeholder="Select option"
                options={Object.entries(SwiftFeePayrollLabel)
                  .slice(0, 3)
                  .map(([key, label]) => ({ value: key, label }))}
                isRequired
                tooltip={
                  <Tooltip id={v4()} clickable style={{ width: '500px' }} content={<SwiftFeePayRoleTooltip />} />
                }
              />
            </div>
          )}
        />
        {errors.swiftFeePayRole && (
          <Typography className="text_regular__14 color_red">{errors.swiftFeePayRole.message}</Typography>
        )}
      </FormControl>
      <Typography className="text_light__12" style={{ color: '#878787' }}>
        If your contractor payment will be done via International wire transfer, the payment rails for contractor
        payments will utilize SWIFT wire transfers. SWIFT fee is fixed at 20 USD per transaction
      </Typography>
    </form>
  )
}
