import { fieldNameMap } from '@core/constants'
import React from 'react'
import styled from 'styled-components'

const Root = styled.div`
  background-color: #fff;
  border: 2px solid #f3f3f3;
  border-radius: 8px;
  padding: 1.5rem;
  min-width: 560px;
  margin-bottom: 1rem;
`
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`
const Title = styled.div`
  color: #121212;
  font-size: 1.25rem;
  font-weight: 600;
`
const Body = styled.div``
const List = styled.div``
const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0.75rem 0;
  border-bottom: 1px solid #f3f3f3;

  &:last-child {
    border-bottom: 0;
  }
`
const Name = styled.div`
  font-size: 0.875rem;
  color: #878787;
`
const Value = styled.div`
  font-size: 0.875rem;
  font-weight: 500;
  color: #121212;
  max-width: 300px;
  text-align: right;
  word-break: break-word;
  white-space: pre-wrap;
`

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
`

export const Info = ({ title, data, actions }) => {
  return (
    <Root>
      <Header>
        <Title>{title}</Title>
        <Actions>{actions?.map((action) => action)}</Actions>
      </Header>
      <Body>
        <List>
          {Object.keys(data).map((key) => (
            <Item key={key}>
              <Name>{fieldNameMap[key]}</Name>
              <Value>{data[key]}</Value>
            </Item>
          ))}
        </List>
      </Body>
    </Root>
  )
}
