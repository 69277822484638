import './EmployeesPage.scss'

import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import ChooseMeetingTime from '@atoms/ChooseMeetingTimeModal'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Select from '@atoms/Select/Select'
import CustomTable from '@atoms/Table/CustomTable'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import ActionModal from '@components/action-modal/action-modal.component'
import { Dropdown } from '@components/dropdown/dropdown.component'
import { CONTRACT_TYPE, EMPLOYEE_STATES, EOR_TYPES } from '@core/constants'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useNotification'
import { useSearchParams } from '@core/hooks/useRouteQuery'
import { addContractor, addWhite, coin, searchIcon } from '@core/icons/icons'
import { DraftContractsTable } from '@features/draft-contracts/draft-contracts-table/draft-contracts-table.component'
import { CompleteOnboardingModal } from '@pages/overview/complete-onboarding-modal/complete-onboarding-modal.component'
import { getDirectManagers } from '@services/contract.service'
import { createDraftContract, updateDraftContract } from '@services/draft-contract.service'
import { exportContractorsXLS, exportEmployeesXLS } from '@services/employee.service'
import { fetchCreateContractor, patchContractById } from '@store/contracts'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { HiDownload } from 'react-icons/hi'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useBoolean, useDebounceValue } from 'usehooks-ts'

import { fetchCompanies, fetchContractors } from '../../services/app.service'
import { ContractorsMassImportModal } from './contractors-mass-import-modal/contractors-mass-import-modal.component'
import CreateContractorModal from './create-contractor/CreateContractorModal'
import CreateEmployeeModal from './create-employee/CreateEmployeeModal'
import { allFields, contractorFields, employeeFields } from './employeeMock'
import { employeeTableParse } from './parse'
import SuccessModal from './success-modal'

export default function EmployeesPage() {
  const { countries, profile, refetchProfile } = useApp()
  const { params, setParams } = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const types = location.search.split('type=')
  const [tab, setTab] = useState(0)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [contracts, setContracts] = useState([])
  const [visibleOnboarding, setVisibleOnboarding] = useState(true)
  const [contractorsState, setContractorsState] = useState('')
  const [contractorType, setContractorType] = useState('')
  const [search, setSearch] = useState(params.get('search') || '')
  const [country_id, setCountryId] = useState(params.get('country_id') || '')
  const [lineManagerId, setLineManagerId] = useState(params.get('direct_manager_id') || '')
  const [draftContractId, setDraftContractId] = useState(null)
  const [debouncedSearch, setDebouncedSearch] = useDebounceValue(search, 500)

  const newEmployeeModalState = useBoolean(false)
  const newContractorModalState = useBoolean(false)
  const massImportModalState = useBoolean(false)
  const bookMeetModalState = useBoolean(false)
  const msaNotSignedModalState = useBoolean(false)
  const dropdownOpenState = useBoolean(false)
  const completeOnboardingModalState = useBoolean(false)

  const [stateIn, setStateIn] = useState([
    EMPLOYEE_STATES.ACTIVE,
    EMPLOYEE_STATES.ARCHIVED,
    EMPLOYEE_STATES.COMPLETED,
    EMPLOYEE_STATES.CREATED,
    EMPLOYEE_STATES.DRAFT,
    EMPLOYEE_STATES.ONBOARDING,
    EMPLOYEE_STATES.SIGNING,
    EMPLOYEE_STATES.INVITED,
  ])

  const { successAlert } = useToast()
  const queryClient = useQueryClient()

  const isNotActive = profile.state !== 'active'

  const isInactiveDisplayed = stateIn.includes(EMPLOYEE_STATES.INACTIVE)

  const [addedNewEmployee, setAddedNewEmployee] = useState(null)
  const continueContract = useBoolean(false)

  const isNotVerified = profile.state === 'created'

  const { data: lineManagers, isLoading } = useQuery(getDirectManagers.key, getDirectManagers.fetch)

  const { isFetching, refetch } = useQuery(
    [fetchContractors.key, contractorsState, contractorType, page, debouncedSearch, country_id, lineManagerId, stateIn],
    {
      queryFn: () =>
        fetchContractors.fetch({
          company_id: profile.id,
          contract_type: contractorType,
          state: contractorsState,
          offset: (page - 1) * 10,
          limit: 10,
          search: debouncedSearch,
          country_id,
          direct_manager_id: lineManagerId,
          state_in: stateIn.join(','),
        }),
      onSuccess: ({ results, count }) => {
        setContracts(employeeTableParse(results, page))
        setTotal(count)
      },
    }
  )

  const isEmpty = !debouncedSearch && !total

  const companyQuery = useQuery('companies', {
    queryFn: () => fetchCompanies(profile.id),
  })

  const exportEmployeesXLSMutation = useMutation({
    mutationFn: exportEmployeesXLS,
    onSuccess: (response) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `full_time_employees_${moment(new Date()).format('DD_MM_YYYY')}.xls`)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      dropdownOpenState.setFalse()
    },
  })

  const createDraftContractMutation = useMutation({
    mutationFn: createDraftContract,
    onSuccess: () => {
      queryClient.refetchQueries('getDraftContractList')
      successAlert('Your changes have been saved as draft!')
    },
  })

  const updateDraftContractMutation = useMutation({
    mutationFn: ({ id, body }) => updateDraftContract(id, body),
    onSuccess: () => {
      queryClient.refetchQueries('getDraftContractList')
      successAlert('Your changes have been saved!')
    },
  })

  const exportContractorsXLSMutation = useMutation({
    mutationFn: exportContractorsXLS,
    onSuccess: (response) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `contractors_${moment(new Date()).format('DD_MM_YYYY')}.xls`)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      dropdownOpenState.setFalse()
    },
  })

  const handleCloseNewEmployeeModal = (payload) => {
    setAddedNewEmployee(null)
    newEmployeeModalState.setFalse()

    const body = { ...(payload || {}), profile: { ...(payload?.profile || {}) } }
    if (body?.profile?.use_remo_check !== undefined) {
      delete body.profile.use_remo_check
    }
    if (body?.profile?.remo_check_plan !== undefined) {
      delete body.profile.remo_check_plan
    }

    if (payload) {
      if (draftContractId) {
        updateDraftContractMutation.mutate({
          id: draftContractId,
          body: {
            data: body,
          },
        })
      } else {
        createDraftContractMutation.mutate({
          data: body,
          company: profile.id,
        })
      }
    }
    setDraftContractId(null)
  }

  const newEmployeeMutation = useMutation({
    mutationFn: (payload) => fetchCreateContractor(payload),
    onSuccess: (v) => {
      refetch()
      refetchProfile()
      handleCloseNewEmployeeModal()
      setAddedNewEmployee(v)
      if (v.contract_type === 'contractor') continueContract.setTrue()
      localStorage.removeItem('newContractorEmail')
    },
  })

  const newEmployeeUpdate = useMutation({
    mutationFn: (variables) => patchContractById(variables.id, variables.data),
    onSuccess: ({ data }) => {
      refetch()
      refetchProfile()
      handleCloseNewEmployeeModal()
      setAddedNewEmployee(data)
      if (data.contract_type === 'contractor') continueContract.setTrue()
      localStorage.removeItem('newContractorEmail')
    },
  })

  const handleAddAnotherPerson = (contract_type) => {
    setAddedNewEmployee(null)

    if (contract_type === CONTRACT_TYPE.CONTRACTOR) {
      newContractorModalState.setTrue()
    }

    if (contract_type === CONTRACT_TYPE.FULL_TIME_EMPLOYEE) {
      newEmployeeModalState.setTrue()
    }
  }

  const onTabChange = (id) => {
    setTab(id)
    setPage(1)
    switch (id) {
      case 0: {
        setContractorsState('')
        setStateIn([
          EMPLOYEE_STATES.ACTIVE,
          EMPLOYEE_STATES.ARCHIVED,
          EMPLOYEE_STATES.COMPLETED,
          EMPLOYEE_STATES.CREATED,
          EMPLOYEE_STATES.DRAFT,
          EMPLOYEE_STATES.ONBOARDING,
          EMPLOYEE_STATES.SIGNING,
          EMPLOYEE_STATES.INVITED,
        ])
        return setContractorType('')
      }
      case 1: {
        setContractorsState('')
        return setContractorType(CONTRACT_TYPE.FULL_TIME_EMPLOYEE)
      }
      case 2: {
        setContractorsState('')
        return setContractorType(CONTRACT_TYPE.CONTRACTOR)
      }
      case 3: {
        setContractorType('')
        setContractorsState('')
        setStateIn([EMPLOYEE_STATES.ONBOARDING, EMPLOYEE_STATES.INVITED])
        return setStateIn([EMPLOYEE_STATES.ONBOARDING, EMPLOYEE_STATES.INVITED])
      }
      default:
        return null
    }
  }

  const handleSetOnboardingTab = (contract_type) => {
    if (contract_type === CONTRACT_TYPE.CONTRACTOR) {
      setTab(2)
      onTabChange(2)
    }

    if (contract_type === CONTRACT_TYPE.FULL_TIME_EMPLOYEE) {
      setTab(3)
      onTabChange(3)
    }
    setAddedNewEmployee(null)
  }

  useEffect(() => {
    if (types.length) {
      onTabChange(+types[1])
      setTab(+types[1])
    }
  }, [location])

  // need to show all otherwise will be showing empty page

  // useEffect(() => {
  //   if (profile.state === 'active') {
  //     onTabChange(2)
  //   }
  // }, [profile])

  const handleSaveNewEmployee = (payload) => {
    const newEmail = localStorage.getItem('newContractorEmail')
    const emailMatch = newEmail && newEmail === payload.profile.email

    if (emailMatch && addedNewEmployee.id) {
      newEmployeeUpdate.mutate({
        id: addedNewEmployee.id,
        data: {
          ...payload,
          company: companyQuery.data.id,
        },
      })
    } else {
      newEmployeeMutation.mutate({
        ...payload,
        company: companyQuery.data.id,
      })
    }
  }

  const onRowClick = (row) => {
    if (row.contract_type === 'contractor' && row.status === 'onboarding') {
      navigate(`${row.id}/contractor-onboarding`)
    } else navigate(`${row.id}`)
  }

  const onCloseOnboarding = () => {
    setVisibleOnboarding(false)
  }

  const onChangeSearch = (evt) => {
    const { value } = evt.target
    setSearch(value)
    setParams({ search: value, country_id, direct_manager_id: lineManagerId })
    setPage(1)
  }

  const onSelectCountry = (evt) => {
    setCountryId(evt.value || '')
    setParams({
      country_id: evt.value || '',
      search,
      direct_manager_id: lineManagerId,
    })
    setPage(1)
  }

  const onSelectLineManager = (evt) => {
    setLineManagerId(evt ? evt?.value : null)
    setParams({
      direct_manager_id: evt?.value || '',
      search,
      country_id,
    })
    setPage(1)
  }

  // @TODO: create a component for adding employee
  const handleAddEmployeeDialog = () => {
    if (profile?.eor_type === EOR_TYPES.SAAS && !profile?.msa_agreement_document_signed) {
      msaNotSignedModalState.setTrue()
    } else if (profile?.source === 'admin') {
      newEmployeeModalState.setTrue()
    } else if (profile?.source === 'self_onboarded') {
      if (!profile.is_verified) {
        bookMeetModalState.setTrue()
      } else {
        newEmployeeModalState.setTrue()
      }
    }
  }

  const handleOpenDraftContract = (id) => {
    setDraftContractId(id)
    newEmployeeModalState.setTrue()
  }

  const handleGoToMeet = () => {
    navigate('/calendly?page=team')
  }

  const handleGoToDashboard = () => {
    navigate('/pages/dashboard')
  }

  const handleInactiveChange = () => {
    setPage(1)
    setStateIn((prev) =>
      prev.includes(EMPLOYEE_STATES.INACTIVE)
        ? prev.filter((state) => state !== EMPLOYEE_STATES.INACTIVE)
        : [...prev, EMPLOYEE_STATES.INACTIVE]
    )
  }

  const handleAddContractor = () => {
    if (profile?.source === 'admin') {
      newContractorModalState.setTrue()
    } else if (profile?.source === 'self_onboarded') {
      if (profile.state !== 'active') {
        completeOnboardingModalState.setTrue()
      } else {
        newContractorModalState.setTrue()
      }
    }
  }

  const handleMassImportModal = () => {
    massImportModalState.setTrue()
    newContractorModalState.setFalse()
  }

  const handleCloseMassImport = () => {
    massImportModalState.setFalse()
    refetch()
  }

  if (companyQuery.isLoading) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={48} speed={1} color="black" />
      </div>
    )
  }

  return (
    <div className="employees-page">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle> Team </PageTitle>
        <div hidden={isEmpty} className="d-flex">
          <Dropdown
            open={dropdownOpenState.value}
            trigger={
              <Button
                data-testid="EmployeesPage-A367D7"
                priority="secondary"
                size="small"
                onClick={dropdownOpenState.toggle}
              >
                <HiDownload className="mr-1" />
                Download report
              </Button>
            }
            menu={[
              <button
                data-testid="EmployeesPage-6B7E6A"
                type="button"
                onClick={exportEmployeesXLSMutation.mutate}
                disabled={exportEmployeesXLSMutation.isLoading}
              >
                Download FTE data as XLS
              </button>,
              <button
                data-testid="EmployeesPage-209F27"
                type="button"
                onClick={exportContractorsXLSMutation.mutate}
                disabled={exportContractorsXLSMutation.isLoading}
              >
                Download contractors data as XLS
              </button>,
            ]}
          />
          <Button
            data-testid="EmployeesPage-3CDDF0"
            size="small"
            priority="secondary"
            className="ml-2"
            onClick={handleAddContractor}
          >
            <Icon icon={addContractor} className="mr-2" />
            Add contractor
          </Button>
          <Button
            data-testid="EmployeesPage-69A346"
            size="small"
            priority="primary_black"
            className="ml-2"
            onClick={handleAddEmployeeDialog}
          >
            <Icon icon={addWhite} className="mr-2" />
            Add employee
          </Button>
        </div>
      </div>
      {!isEmpty || profile?.has_contracts ? (
        <div className="d-flex mb-4 align-items-center gap-3">
          <Input
            data-testid="EmployeesPage-03313A"
            onChange={onChangeSearch}
            placeholder="Search"
            type="text"
            name="search"
            value={search}
            endIcon={<Icon icon={searchIcon} />}
            styleClass="employees-page-search"
          />
          <div style={{ width: 190 }}>
            <Select
              data-testid="EmployeesPage-A260DA"
              placeholder="Select country"
              value={country_id ? +country_id : undefined}
              onChange={onSelectCountry}
              options={[
                { value: 0, label: 'All' },
                ...countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                })),
              ]}
            />
          </div>
          <div style={{ width: 190 }}>
            <Select
              placeholder="Line manager"
              value={lineManagerId}
              onChange={onSelectLineManager}
              options={lineManagers?.results?.map((manager) => ({
                label: `${manager.profile.first_name} ${manager.profile.last_name}`,
                value: manager.id,
              }))}
              openMenuOnClick={false}
              loding={isLoading}
              isClearable
            />
          </div>
          <Checkbox
            data-testid="EmployeesPage-D55631"
            checked={isInactiveDisplayed}
            label="Inactive"
            onChange={handleInactiveChange}
          />
        </div>
      ) : null}

      <NotificationBlock
        text="How onboarding works?"
        cards={[
          {
            title: 'Add your team members',
            description: 'You add your employee’s information to Remofirst',
            icon: coin,
          },
          {
            title: 'We invite your team members',
            description: 'We invite them to the platform and sign the contract with them',
            icon: coin,
          },
          {
            title: 'Notification',
            description: 'You will receive notification once everything is ready',
            icon: coin,
          },
        ]}
        isExpanded={isEmpty}
      />

      {!isEmpty || profile?.has_contracts ? (
        <Tabs selectedTab={tab} onTabChange={onTabChange}>
          <Tab tabId={0} title={isNotVerified ? 'My Team' : 'Everyone'}>
            <CustomTable
              fields={allFields}
              page={page}
              loading={isFetching}
              total={total}
              onPage={setPage}
              data={contracts}
              onRowClick={onRowClick}
            />
          </Tab>
          <Tab tabId={1} title="Full-time" hidden={isNotVerified}>
            <CustomTable
              fields={employeeFields}
              data={contracts}
              page={page}
              loading={isFetching}
              total={total}
              onPage={setPage}
              onRowClick={onRowClick}
            />
          </Tab>
          <Tab tabId={2} title="Contractors" hidden={isNotVerified}>
            <CustomTable
              fields={contractorFields}
              page={page}
              loading={isFetching}
              total={total}
              onPage={setPage}
              data={contracts}
              onRowClick={onRowClick}
            />
          </Tab>
          <Tab tabId={3} title="Onboarding" hidden={isNotVerified}>
            <CustomTable
              fields={allFields}
              page={page}
              loading={isFetching}
              total={total}
              onPage={setPage}
              data={contracts}
              onRowClick={onRowClick}
            />
          </Tab>
          <Tab tabId={4} title="Drafts" hidden={isNotVerified}>
            <DraftContractsTable onDraftOpen={handleOpenDraftContract} />
          </Tab>
        </Tabs>
      ) : null}

      {(isEmpty && !profile?.has_contracts && (
        <div className="d-flex justify-content-center align-items-center flex-grow-1">
          <div className="text-center">
            <img src="/assets/img/notFound.png" alt="" />
            <p className="heading_semibold__20 mb-3 mt-4">Start inviting your team members</p>
            <p className="text_light__14 mb-4">Once you add new employees or contractors, you will see them here</p>
            <div className="d-flex align-items-center justify-content-center">
              <Button
                data-testid="EmployeesPage-DE72E1"
                size="small"
                priority="secondary"
                onClick={handleAddContractor}
              >
                <Icon icon={addContractor} className="mr-2" />
                Add contractor
              </Button>
              <Button
                data-testid="EmployeesPage-5ED969"
                size="small"
                priority="primary_black"
                className="ml-2"
                onClick={handleAddEmployeeDialog}
              >
                <Icon icon={addWhite} className="mr-2" />
                Add employee
              </Button>
            </div>
          </div>
        </div>
      )) ||
        ''}

      {newEmployeeModalState.value && (
        <CreateEmployeeModal
          loading={newEmployeeMutation.isLoading || newEmployeeUpdate.isLoading}
          onClose={handleCloseNewEmployeeModal}
          onSave={handleSaveNewEmployee}
          draftId={draftContractId}
        />
      )}
      {newContractorModalState.value && (
        <CreateContractorModal
          addedNewEmployee={addedNewEmployee}
          isContinuedContract={continueContract.value}
          onCloseContractModal={continueContract.setFalse}
          onMassImport={handleMassImportModal}
          loading={newEmployeeMutation.isLoading || newEmployeeUpdate.isLoading}
          onClose={() => {
            handleCloseNewEmployeeModal()
            newContractorModalState.setFalse()
          }}
          onSave={handleSaveNewEmployee}
        />
      )}
      {!!addedNewEmployee && addedNewEmployee.contract_type === 'full_time_employee' && (
        <SuccessModal
          companyName={companyQuery.data.name}
          employee={addedNewEmployee}
          onAdd={handleAddAnotherPerson}
          onBoarding={handleSetOnboardingTab}
        />
      )}

      {bookMeetModalState.value && <ChooseMeetingTime onClose={bookMeetModalState.setFalse} onMeet={handleGoToMeet} />}

      {msaNotSignedModalState.value && (
        <ActionModal
          imgSrc="/assets/img/sign-document.svg"
          title="Please sign the MSA to start hiring full-time employees globally"
          description="Once you sign the Master Service Agreement, you will be able to add new employees to the platform"
          buttonText="Go to Dashboard to sign"
          onAction={handleGoToDashboard}
          onClose={msaNotSignedModalState.setFalse}
        />
      )}

      {completeOnboardingModalState.value && (
        <CompleteOnboardingModal onClose={completeOnboardingModalState.setFalse} />
      )}

      {massImportModalState.value && <ContractorsMassImportModal onClose={handleCloseMassImport} />}
    </div>
  )
}
