import StepContent from '@atoms/Stepper/StepContent'
import { EOR_TYPES } from '@core/constants'
import { useApp } from '@core/context'
import { StringToBoolean } from '@core/utils'
import { fetchCountryOccupations, fetchCountryRegions } from '@services/countries.service'
import { getDraftContractDetails } from '@services/draft-contract.service'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import Step from '../../../atoms/Stepper/Step'
import Stepper from '../../../atoms/Stepper/Stepper'
import { getAge, getCalculationsContractType, parsePayload } from './create-employee.utils'
import BenefitsForm from './forms/Benefit/BenefitsForm'
import CompensationForm from './forms/CompensationForm'
import HiringDetailsForm from './forms/HiringDetailsForm'
import JobDetailsForm from './forms/JobDetailsForm'
import LocationForm from './forms/LocationForm'
import PersonalInformationForm from './forms/PersonalInformationForm'
import { TimeOffPolicyStep } from './forms/time-off-policy-step/time-off-policy-step.component'
import { PaymentModal } from './payment-modal/payment-modal.component'
import { SignAppendixStep } from './sign-appendix-step/sign-appendix-step.component'

const hiringStepTitle = 'Country of hire'
const personalStepTitle = 'Employee information'
const locationStepTitle = 'Home address'
const jobStepTitle = 'Job details'
const compensationStepTitle = 'Compensation'
const benefitsStepTitle = 'RemoPlus'
const appendixStepTitle = 'Sign MSA appendix'
const timeOffPolicy = 'Time off policy'

export default ({ loading, onClose, onSave, draftId }) => {
  const { profile } = useApp()
  const [activeStep, setStep] = useState(1)
  const [workingCountryId, setWorkingCountryId] = useState(null)
  const [workingCountry, setWorkingCountry] = useState(null)
  const [occupations, setOccupations] = useState([])
  const [regionOptions, setRegionOptions] = useState([])
  const [regionName, setRegionName] = useState('')

  const [payload, setPayload] = useState(null)

  const paymentModalOpen = useBoolean(true)
  const [checked, setChecked] = useState(false)

  const { data: draftContract } = useQuery('getDraftContractDetails', () => getDraftContractDetails(draftId), {
    enabled: !!draftId,
    onSuccess: (data) => {
      setPayload(parsePayload(data.data))
    },
  })

  const occupationsData = useQuery('getCountryOccupations', () => fetchCountryOccupations(workingCountryId), {
    enabled: !!workingCountry?.hasOccupations,
    onSuccess: (data) => {
      setOccupations(data.results)
    },
  })

  const handleBack = () => setStep((prev) => prev - 1)

  const handleNextNoData = () => {
    setStep((prev) => Number(prev) + 1)
  }

  const handleNext = (formValues) => {
    setPayload((prev) => ({
      ...prev,
      ...formValues,
    }))
    setStep((prev) => Number(prev) + 1)
  }

  const handleHiringDetailsSubmit = (data) => {
    setWorkingCountryId(data.workingCountryId)
    setWorkingCountry(data.workingCountry)
    setPayload((prev) => ({
      ...prev,
      working_country: data.workingCountryId,
    }))
    if (data.region) {
      setPayload((prev) => ({
        ...prev,
        region: data.region?.value || data.region,
      }))
      setRegionName(data.region.label)
    }
    setStep((prev) => Number(prev) + 1)
  }

  const handleLocationSubmit = (formValues) => {
    setPayload((prev) => ({
      ...prev,
      profile: {
        ...prev.profile,
        address: formValues,
      },
    }))
    setStep((prev) => prev + 1)
  }

  const handleSave = (benefit, insurance) => {
    const body = {
      ...payload,
      contract_type: 'full_time_employee',
      benefit: { ...benefit },
      insurance,
    }

    if (StringToBoolean(body.job.is_permanent)) {
      delete body.job.ends_at
    }
    if (!body.benefit.provides_work_equipment) {
      delete body.benefit.equipment_specification
      delete body.benefit.delivered_at
    }
    if (!insurance?.insurance_price) delete body.insurance

    onSave(body)
  }

  const hasAppendix = () => {
    return profile?.eor_type === EOR_TYPES.SAAS
  }

  const handleClose = () => {
    if (draftContract) {
      onClose(payload)
    } else {
      onClose(payload)
    }
  }

  const handlePaymentCheckboxChange = (e) => {
    setChecked(e.target.checked)
  }

  return (
    <>
      <Modal show onClose={handleClose} fullscreen>
        <Modal.Body className="p-0">
          <Stepper
            activeStep={activeStep}
            title="Add a new employee"
            description="Remofirst will act as your employer of record and handle all administrative and legal processes."
            sidebarStyle={{ width: '340px' }}
          >
            <Step title={hiringStepTitle} setStep={setStep}>
              <StepContent title={hiringStepTitle} onClose={handleClose}>
                <HiringDetailsForm
                  onNext={handleHiringDetailsSubmit}
                  hasAppendix={hasAppendix()}
                  draft={draftContract?.data}
                  setRegionOptions={setRegionOptions}
                  regionOptions={regionOptions}
                />
              </StepContent>
            </Step>

            {hasAppendix() && (
              <Step title={appendixStepTitle} setStep={setStep}>
                <StepContent title={appendixStepTitle} onClose={handleClose} onBack={handleBack}>
                  <SignAppendixStep
                    workingCountryId={workingCountryId}
                    onNext={handleNextNoData}
                    onClose={handleClose}
                  />
                </StepContent>
              </Step>
            )}

            <Step title={personalStepTitle} setStep={setStep}>
              <StepContent title={personalStepTitle} onClose={handleClose} onBack={handleBack}>
                <PersonalInformationForm onNext={handleNext} onBack={handleBack} draft={draftContract?.data} />
                {/* <CompensationForm
                  working_country={workingCountryId}
                  onBack={handleBack}
                  onNext={handleNext}
                  draft={draftContract?.data}
                  age={getAge(payload?.profile?.birth_date)}
                  contractType={getCalculationsContractType(payload?.job?.is_permanent)}
                  region={payload?.region}
                  occupation={payload?.job?.occupation}
                  residencyType={payload?.residency_type}
                /> */}
              </StepContent>
            </Step>

            <Step title={locationStepTitle} setStep={setStep}>
              <StepContent title={locationStepTitle} onClose={handleClose} onBack={handleBack}>
                <LocationForm
                  livingCountry={workingCountryId}
                  onBack={handleBack}
                  onNext={handleLocationSubmit}
                  draft={draftContract?.data}
                  regionName={regionName}
                />
              </StepContent>
            </Step>
            <Step title={jobStepTitle} setStep={setStep}>
              <StepContent title={jobStepTitle} onClose={handleClose} onBack={handleBack}>
                <JobDetailsForm
                  onBack={handleBack}
                  onNext={handleNext}
                  livingCountryName={workingCountry?.name}
                  workingCountryId={workingCountryId}
                  draft={draftContract?.data}
                  hasRegions={!!workingCountry?.has_regions}
                  regionOptions={regionOptions}
                  hasOccupations={!!workingCountry?.hasOccupations}
                  occupations={occupations}
                />
              </StepContent>
            </Step>

            <Step title={compensationStepTitle} setStep={setStep}>
              {workingCountryId && (
                <StepContent title={compensationStepTitle} onClose={handleClose} onBack={handleBack}>
                  <CompensationForm
                    working_country={workingCountryId}
                    onBack={handleBack}
                    onNext={handleNext}
                    draft={draftContract?.data}
                    age={getAge(payload?.profile?.birth_date)}
                    contractType={getCalculationsContractType(payload?.job?.is_permanent)}
                    region={payload?.region}
                    occupation={payload?.job?.occupation}
                    residencyType={payload?.residency_type}
                  />
                </StepContent>
              )}
            </Step>

            <Step title={timeOffPolicy} setStep={setStep}>
              <StepContent title={timeOffPolicy} onClose={handleClose} onBack={handleBack}>
                <TimeOffPolicyStep workingCountryId={workingCountryId} onNext={handleNext} />
              </StepContent>
            </Step>

            <Step title={benefitsStepTitle} setStep={setStep}>
              <StepContent title={benefitsStepTitle} onClose={handleClose} onBack={handleBack}>
                <BenefitsForm
                  workingCountry={workingCountry}
                  loading={loading}
                  onBack={handleBack}
                  onFinish={handleSave}
                />
              </StepContent>
            </Step>
          </Stepper>
        </Modal.Body>
      </Modal>
      {paymentModalOpen.value && (
        <PaymentModal checked={checked} onChange={handlePaymentCheckboxChange} onClose={paymentModalOpen.setFalse} />
      )}
    </>
  )
}
