import Step from '@atoms/Stepper/Step'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import { TEMPLATE_TYPES } from '@core/constants'
import { AppContext } from '@core/context'
import { client } from '@core/hellosign-client'
import { useToast } from '@core/hooks/useNotification'
import { getEmployeeDetails } from '@services/employee.service'
import { createAgreement, getActiveTemplate, getAgreement, getSignUrl } from '@services/sign.service'
import { patchContractById } from '@store/contracts'
import { DotWave } from '@uiball/loaders'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import CompensationForm from '../../../pages/employees/create-contractor/form/CompensationForm'
import JobDetailsForm from '../../../pages/employees/create-contractor/form/JobDetailsForm'
import { CompanyInformation } from './company-information/company-information'
import { ConfirmSignatory } from './confirm-signatory/confirm-signatory'
// import { ConfirmTemplateModal } from './confirm-template-modal/confirm-template-modal'
import { ContractorStep } from './contractor-step/contractor-step'
import { Review } from './review/review'
import { SuccessCreatedContractModal } from './success-created-contract-modal/success-created-contract-modal'

const step1 = 'Contractor'
const step2 = 'Job details'
const step3 = 'Compensation'
const step4 = 'Confirm your company information'
const step5 = 'Confirm signatory'
const step6 = 'Review & Send'

export const CreateContract = ({ contractorId: id, onClose, refetch: refetchAgreement }) => {
  const { profile, userProfile } = useContext(AppContext)
  const { successAlert } = useToast()
  const [activeStep, setStep] = useState(1)
  const [signers, setSigners] = useState([])
  const [contractorId, setContractorId] = useState(id)
  const successModalOpen = useBoolean(false)
  const isAorCompany = profile?.is_aor_company

  const [payload, setPayload] = useState({
    contract_type: 'contractor',
  })

  const {
    data: contractor,
    refetch,
    isLoading,
    isFetching,
  } = useQuery(['detailEmployee', contractorId], {
    queryFn: () => getEmployeeDetails(contractorId),
    enabled: !!contractorId,
  })

  const {
    mutate: mutateSign,
    data: signUrl,
    isLoading: isLoadingSign,
  } = useMutation(['signUrl'], {
    mutationFn: getSignUrl,
    onSuccess: ({ sign_url }) => {
      client.open(sign_url, {
        skipDomainVerification: process.env.REACT_APP_HELLOSIGN_SKIP_DOMAIN === 'true',
      })
    },
  })

  const { mutate: mutateAgreement, isLoading: isLoadingAgreement } = useMutation(['getAgreement'], {
    mutationFn: getAgreement,
    onSuccess: (data) => {
      const signatureId = data?.signatures?.find((e) => e.user.email === userProfile.profile.email)?.id
      mutateSign(signatureId)
    },
  })

  const getAgreementQuery = useMutation(() =>
    getActiveTemplate({
      status: 'active',
      template_type: isAorCompany ? 'aor_contract' : 'contractor_employment',
    })
  )

  const createContractMutation = useMutation({
    mutationFn: createAgreement,
    onSuccess: (res) => {
      if (userProfile.profile.email === signers[0]?.email_address) {
        mutateAgreement(res?.id)
      } else {
        successModalOpen.setTrue()
      }
    },
  })

  useEffect(() => {
    if (signUrl) {
      client.on('finish', () => {
        successAlert('Agreement has been signed! It may take some time to change status.')
      })
      successModalOpen.setTrue()
    }
    return () => {
      client.off('finish')
    }
  }, [signUrl])
  const handleBack = () => setStep((prev) => prev - 1)

  const handleNext = (formValues) => {
    if (formValues)
      setPayload((prev) => ({
        ...prev,
        ...formValues,
      }))
    if (formValues?.id) setContractorId(formValues.id)
    setStep((prev) => Number(prev) + 1)
  }

  const contractUpdateMutation = useMutation({
    mutationFn: (variables) => patchContractById(variables.id, variables.data),
    onSuccess: () => {
      handleNext()
      refetch()
    },
  })

  const handleFinish = (formValues) => {
    setPayload((prev) => {
      const body = { ...prev, ...formValues }

      contractUpdateMutation.mutate({ id: contractorId, data: body })

      return body
    })
  }

  const handleNextSigner = (formValues) => {
    if (formValues) setSigners([formValues])
    setStep((prev) => Number(prev) + 1)
  }

  const onSubmit = async () => {
    let agreement
    try {
      agreement = await getAgreementQuery.mutateAsync()
      const agreementId = agreement.results.length && agreement.results[0].id
      createContractMutation.mutate({
        agreement_template: agreementId,
        signers: [
          ...signers,
          {
            role_name: 'Contractor',
            name: `${contractor?.profile.first_name} ${contractor.profile.last_name} ${
              contractor.profile.middle_name || ''
            }`,
            email_address: contractor.profile.email,
          },
        ],
        title: `${profile.name} & ${contractor.profile.first_name} ${contractor.profile.last_name} Agreement`,
        subject: '',
        agreement_document_type: isAorCompany ? TEMPLATE_TYPES.AOR_CONTRACT : TEMPLATE_TYPES.CONTRACTOR_EMPLOYMENT,
        company: !isAorCompany ? profile.id : undefined,
        contract: contractor.id,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleClose = () => {
    onClose()
    refetchAgreement()
  }

  const steps = {
    1: (
      <Step step="1" title={step1} setStep={setStep}>
        <StepContent title={step1} onClose={onClose}>
          <ContractorStep onNext={handleNext} />
        </StepContent>
      </Step>
    ),
    2: (
      <Step step="2" title={step2} setStep={setStep} disabled={activeStep <= 1}>
        <StepContent title={step2} onClose={onClose} onBack={handleBack}>
          {isLoading || isFetching ? <DotWave /> : <JobDetailsForm initValue={contractor} onNext={handleNext} />}
        </StepContent>
      </Step>
    ),
    3: (
      <Step step="3" title={step3} setStep={setStep} disabled={activeStep <= 2}>
        <StepContent title={step3} onClose={onClose} onBack={handleBack}>
          {contractor && (
            <CompensationForm
              isEdit
              loading={contractUpdateMutation.isLoading}
              initValue={{
                ...contractor,
                department: payload.department,
                job: { ...contractor.job, ...payload.job },
              }}
              workingCountryId={payload?.working_country}
              onFinish={handleFinish}
            />
          )}
        </StepContent>
      </Step>
    ),
    4: (
      <Step step="4" title={step4} setStep={setStep} disabled={activeStep <= 3}>
        <StepContent title={step4} onClose={onClose}>
          <CompanyInformation onNext={handleNext} />
        </StepContent>
      </Step>
    ),
    5: (
      <Step step="5" title={step5} setStep={setStep} disabled={activeStep <= 4}>
        <StepContent title={step5} onClose={onClose} onBack={handleBack}>
          <ConfirmSignatory onNext={handleNextSigner} />
        </StepContent>
      </Step>
    ),
    6: (
      <Step step="6" title={step6} setStep={setStep} disabled={activeStep <= 5}>
        <StepContent title={step6} onClose={onClose} onBack={handleBack}>
          <Review
            isAorCompany={isAorCompany}
            signers={signers}
            setSigners={setSigners}
            contractor={contractor}
            refetchContractor={refetch}
            onNext={onSubmit}
            loading={
              createContractMutation.isLoading || getAgreementQuery.isLoading || isLoadingAgreement || isLoadingSign
            }
          />
        </StepContent>
      </Step>
    ),
  }

  return (
    <Modal show onClose={onClose} fullscreen>
      <Modal.Body className="p-0">
        <Stepper
          activeStep={activeStep}
          sidebarStyle={{ width: 400, paddingTop: 40, paddingLeft: 32 }}
          title="Creating a contract"
          description="Using Remofirst’s templates we will create a legally compliant contract for all parties to review and sign."
        >
          {!id && steps[1]}
          {!id && steps[2]}
          {!id && steps[3]}
          {!isAorCompany && steps[4]}
          {!isAorCompany && steps[5]}
          {steps[6]}
        </Stepper>
      </Modal.Body>

      {successModalOpen.value && (
        <SuccessCreatedContractModal
          isAuthorized={isAorCompany ? true : signers.length && signers[0]?.isAuthorized}
          contractorEmail={contractor?.profile?.email}
          managerEmail={signers[0]?.email_address}
          onClose={handleClose}
          onSave={handleClose}
        />
      )}
    </Modal>
  )
}
