import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Toggle from '@atoms/Toggle'
import Typography from '@atoms/Typography/Typography'
import { notificationIcon } from '@core/icons/icons'
import { StringToBoolean } from '@core/utils'
import { fetchCountryPaidLeavesById, fetchCountrySpecificPaidById } from '@services/countries.service'
import { getHolidays } from '@services/holidays.service'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'

const getCustomStartDate = () => {
  const customStartDate = new Date('2024-01-04')
  const startDate = new Date()
  startDate.setDate(startDate.getDate() + 8)
  if (moment(startDate).isBefore(customStartDate)) return customStartDate
  return startDate
}

export default ({ onNext, draft, hasOccupations, occupations }) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      position: '',
      position_description: '',
      starts_at: '',
      ends_at: '',
      department: '',
      is_permanent: 'false',
    },
  })

  const starts_at_watch = watch('starts_at')
  const is_permanent_watch = StringToBoolean(watch('is_permanent'))

  const startDate = new Date()
  startDate.setDate(startDate.getDate() + 8)

  useEffect(() => {
    if (draft?.job) {
      reset({
        position: draft.job.position,
        position_description: draft.job.position_description,
        starts_at: new Date(draft.job.starts_at),
        ends_at: new Date(draft.job.ends_at),
        department: draft.department,
        is_permanent: draft.job.is_permanent.toString(),
        occupation: draft.job?.occupation,
      })
    }
  }, [draft])

  useEffect(() => {
    if (!draft?.job?.ends_at) {
      setValue('ends_at', '')
    }
  }, [starts_at_watch, setValue])

  const submit = ({ ends_at, starts_at, department, ...formValues }) => {
    const { sick_days, annual_leave, ...otherFormValues } = formValues
    if (otherFormValues.occupation !== undefined) {
      otherFormValues.occupation = otherFormValues.occupation.value
    }
    onNext({
      job: {
        ...otherFormValues,
        ends_at: moment(ends_at).format('YYYY-MM-DD'),
        requested_starts_at: moment(starts_at).format('YYYY-MM-DD'),
        is_permanent: otherFormValues.is_permanent === 'true',
      },
      department,
    })
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="d-flex flex-column h-100 employees-page__form">
      <section className=" flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <Input
            data-testid="JobDetailsForm-C5F9D9"
            {...register('position', {
              required: 'Job title is required',
            })}
            type="text"
            label="Job title"
            placeholder="e.g. Developer"
          />
          {errors.position && <Typography className="text_regular__14 color_red">{errors.position.message}</Typography>}
        </div>

        <div className="w-100 remo-form-input">
          <TextArea
            {...register('position_description', {
              required: 'Job description is required',
            })}
            maxLength={2000}
            label="Job description"
            placeholder="Describe general tasks or other related duties and responsibilities of the position"
          />
          {errors.position_description && (
            <Typography className="text_regular__14 color_red">{errors.position_description.message}</Typography>
          )}
        </div>

        {hasOccupations && occupations && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="occupation"
              rules={{ required: 'Occupation type is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  data-testid="JobDetailsForm-9132B1"
                  label="Occupation type"
                  placeholder="Select"
                  options={occupations?.map((o) => ({ value: o.id, label: o.name })) || []}
                  isRequired
                />
              )}
            />
            {errors.occupation && (
              <Typography className="text_regular__14 color_red">{errors.occupation.message}</Typography>
            )}
          </div>
        )}

        <div className="w-100 remo-form-input">
          <Input
            data-testid="JobDetailsForm-911CB1"
            {...register('department')}
            type="text"
            label="Department"
            placeholder="Department"
          />
        </div>

        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="is_permanent"
            rules={{ required: 'Type of contract is required' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Type of contract"
                addText={`Please choose type of contract: Fixed-term for a specified period \n Permanent - without specific date of contract termination`}
                options={[
                  {
                    id: 'is_permanent_true',
                    value: false,
                    text: 'Fixed-term',
                  },
                  {
                    id: 'is_permanent_false',
                    value: true,
                    text: 'Permanent',
                  },
                ]}
              />
            )}
          />
          {errors.is_permanent && (
            <Typography className="text_regular__14 color_red">{errors.is_permanent.message}</Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="starts_at"
            rules={{ required: 'Start date is required' }}
            render={({ field }) => {
              return (
                <DateInput
                  {...field}
                  minDate={getCustomStartDate()}
                  label="Start date"
                  addText="You can choose a start date no earlier than 7 working days from today. If employee needs to start earlier, we can pay a sign-in bonus to make up the difference. But the official start date will be not earlier than seven days from today"
                />
              )
            }}
          />
          {errors.starts_at && (
            <Typography className="text_regular__14 color_red">{errors.starts_at.message}</Typography>
          )}
        </div>

        {!is_permanent_watch && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="ends_at"
              rules={{ required: 'End date is required' }}
              render={({ field }) => {
                return <DateInput {...field} label="End date" minDate={starts_at_watch} />
              }}
            />
            {errors.ends_at && <Typography className="text_regular__14 color_red">{errors.ends_at.message}</Typography>}
          </div>
        )}
      </section>

      <div className="align-self-center pb-5">
        <Button data-testid="JobDetailsForm-7305CD" type="submit" className="align-self-end">
          Continue
        </Button>
      </div>
    </form>
  )
}
