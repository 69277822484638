// eslint-disable-next-line import/no-cycle
import { CreateContract } from '@features/agreements/create-contract/create-contract'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import ContractFormSelect from './contract-form-select/contract-form-select'
import { CreateContractModal } from './create-contract-modal/create-contract-modal'
import { UploadContract } from './upload-contract/upload-contract'

export const ContractForm = ({ updateContractOpen, contractor, onClose }) => {
  const [contractType, setContractType] = useState('create')
  const contractBeforeModalOpen = useBoolean(false)
  const createContractOpen = useBoolean(false)
  const navigate = useNavigate()

  return (
    <>
      {!updateContractOpen.value && (
        <ContractFormSelect
          onClose={onClose}
          onNext={contractType === 'upload' ? updateContractOpen.setTrue : contractBeforeModalOpen.setTrue}
          setContractType={setContractType}
          contractType={contractType}
        />
      )}
      {updateContractOpen.value && contractType === 'upload' && (
        <UploadContract onClose={onClose} contractor={contractor} />
      )}
      {contractBeforeModalOpen.value && (
        <CreateContractModal onClose={contractBeforeModalOpen.setFalse} onSave={createContractOpen.setTrue} />
      )}
      {createContractOpen.value && (
        <CreateContract contractorId={contractor.id} onClose={onClose} refetch={() => navigate('/pages/agreements')} />
      )}
    </>
  )
}
