/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import 'react-toastify/dist/ReactToastify.css'
import './router.scss'

import AlertMFA from '@atoms/AlertMFA'
import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import ErrorFallback from '@atoms/ErrorFallback'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { Sidebar } from '@components/sidebar/sidebar'
import { USER_GROUPS } from '@core/constants'
import { useApp } from '@core/context'
import {
  agreements,
  appsIcons,
  benefits,
  documentSyncFilled,
  expenses,
  invoices,
  notificationNone,
  overview,
  payContractors,
  paymentMethods,
  payroll,
  salaryRevision,
  team,
  timeOff,
} from '@core/icons/icons'
import { userHasOneOfGroupsOrSuper } from '@core/utils'
import { getFeatureFlagList } from '@services/feature-flag.service'
import { getMergeInformation } from '@services/merge.service'
import { DotWave } from '@uiball/loaders'
import { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useMutation, useQuery } from 'react-query'
import { Outlet, useNavigate } from 'react-router-dom'
import { useAuth } from 'src/AuthProvider'
import { useBoolean } from 'usehooks-ts'

import { fetchContractors, fetchOnboarding, fetchPopUpMfa, postUserReport } from '../services/app.service'

const sideBarItems = ({ profile, userGroups, contractors, mergeIsActive = false, showFeature = false }) =>
  profile
    ? [
        {
          title: '',
          children: [{ name: 'Overview', icon: overview, url: 'dashboard', count: 0 }],
        },
        {
          title: 'People',
          children: [
            {
              name: 'Team',
              icon: team,
              url: 'team',
              hidden: !userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST]),
            },
            {
              name: 'Time off',
              icon: timeOff,
              url: 'timeoff',
              hidden:
                profile.state === 'created' ||
                !userHasOneOfGroupsOrSuper(userGroups, [
                  USER_GROUPS.HR_SPECIALIST,
                  USER_GROUPS.PTO_MANAGER,
                  USER_GROUPS.PTO_VIEWER,
                ]),
            },
            {
              name: 'Expenses',
              icon: expenses,
              url: 'expense',
              hidden:
                profile.state === 'created' ||
                !userHasOneOfGroupsOrSuper(userGroups, [
                  USER_GROUPS.HR_SPECIALIST,
                  USER_GROUPS.EXPENSE_MANAGER,
                  USER_GROUPS.EXPENSE_VIEWER,
                ]),
            },
            {
              name: 'Additional payments',
              icon: documentSyncFilled,
              url: 'additional-payments',
              hidden:
                profile.state === 'created' ||
                !userHasOneOfGroupsOrSuper(userGroups, [
                  USER_GROUPS.HR_SPECIALIST,
                  USER_GROUPS.FINANCE_SPECIALIST,
                  USER_GROUPS.FINANCE_AP_SPECIALIST,
                ]),
            },
            {
              name: 'Salary revision',
              icon: salaryRevision,
              url: 'salary-revision',
              hidden: !userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST]),
            },
            {
              name: 'Benefits',
              icon: benefits,
              url: 'benefits',
              hidden: !userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST]),
            },
          ],
          hidden: !userHasOneOfGroupsOrSuper(userGroups, [
            USER_GROUPS.HR_SPECIALIST,
            USER_GROUPS.PTO_MANAGER,
            USER_GROUPS.PTO_VIEWER,
            USER_GROUPS.EXPENSE_MANAGER,
            USER_GROUPS.EXPENSE_VIEWER,
            USER_GROUPS.FINANCE_SPECIALIST,
            USER_GROUPS.FINANCE_AP_SPECIALIST,
          ]),
        },
        {
          title: 'Payment',
          children: [
            {
              name: 'Payroll',
              icon: payroll,
              url: 'payrolls',
              hidden: showFeature || profile.state === 'created',
            },
            {
              name: 'Payroll',
              icon: payroll,
              url: 'flag_payrolls',
              hidden: !showFeature || profile.state === 'created',
            },
            { name: 'Invoices', icon: invoices, url: 'invoices' },
            {
              name: 'Payment methods',
              icon: paymentMethods,
              url: 'stripe',
              hidden:
                !profile?.is_allowed_ach_payment ||
                contractors?.results.length === 0 ||
                !userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.FINANCE_AP_SPECIALIST]),
            },
            {
              name: 'Pay contractors',
              icon: payContractors,
              url: 'pay-contractors',
            },
          ],
          hidden: !userHasOneOfGroupsOrSuper(userGroups, [
            USER_GROUPS.FINANCE_AP_SPECIALIST,
            USER_GROUPS.FINANCE_SPECIALIST,
          ]),
        },
        {
          title: 'Documents',
          children: [
            {
              name: 'Agreements',
              icon: agreements,
              url: 'agreements',
            },
          ],
          hidden: !userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST]),
        },
        {
          title: 'Organization',
          hidden: !mergeIsActive,
          children: [
            {
              name: 'Apps & Integrations',
              icon: appsIcons,
              url: 'merge',
            },
          ],
        },
      ]
    : []

const handleReset = () => {
  window.location.href = '/'
}

function Pages() {
  const navigate = useNavigate()
  const { userProfile, profile, userGroups, linkedAccounts } = useApp()
  const { logout, sessions } = useAuth()
  const [deactivateVisible, setDeactivateVisible] = useState(false)

  const { isLoading: onBoardingStatusLoading, data: onBoardingStatus } = useQuery(['onBoardingStatus', userProfile], {
    queryFn: () => fetchOnboarding(),
    enabled: !!userProfile?.role && userProfile?.role === 'company_owner',
    onSuccess: (response) => {
      if (
        userProfile?.role === 'company_owner' &&
        response.onboarding_state === 'NOT_PASSED' &&
        profile.source === 'admin'
      ) {
        navigate('/onboarding')
      }
    },
  })

  const popupMFAQuery = useQuery('popupMFA', fetchPopUpMfa)
  const popupMFAOpen = useBoolean(true)

  const { data: featureFlag } = useQuery('getFeatureFlagList', () => getFeatureFlagList())
  const getFeature = featureFlag?.filter((ff) => ff.feature_name === 'client_payroll_approval')
  const showFeature =
    getFeature &&
    getFeature[0] &&
    (getFeature[0].companies_full_access || getFeature[0].companies.some((partner) => partner.id === profile.id))
  const mergeInformationQuery = useQuery([getMergeInformation.key], {
    queryFn: getMergeInformation.fetch,
  })

  const userReport = useMutation('userReport', {
    mutationFn: (body) => postUserReport(body),
  })

  const { data: contractors } = useQuery([fetchContractors.key], {
    queryFn: () =>
      fetchContractors.fetch({
        company_id: profile.id,
        offset: 0,
        contract_type: 'full_time_employee',
        limit: 10,
      }),
  })

  useEffect(() => {
    userReport.mutate({
      event_name: 'active',
      metadata: {
        start_time: new Date(),
      },
    })
  }, [])

  useEffect(() => {
    if (showFeature && window.location.pathname === '/pages/payrolls') {
      navigate('/pages/flag_payrolls')
    }
  }, [showFeature])

  const isVisibleBookMeeting = () => {
    return profile?.source !== 'admin' && !profile.is_verified
  }

  if (onBoardingStatusLoading) {
    return (
      <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: '100vh' }}>
        <DotWave size={48} speed={1} color="black" />
      </div>
    )
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={handleReset}>
      <div className="d-flex h-100 w-100">
        <Sidebar
          firstName={userProfile?.profile.first_name}
          lastName={userProfile?.profile.last_name}
          items={sideBarItems({
            profile,
            userGroups,
            contractors,
            mergeIsActive: mergeInformationQuery.data?.has_access,
            showFeature,
          })}
          bookMeeting={isVisibleBookMeeting()}
          showChat={userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST])}
          profilePagePath="/pages/profile"
          linkedAccounts={linkedAccounts}
          sessions={sessions}
          onLogout={logout}
        />
        <main className="h-100 flex-grow-1 overflow-auto">
          {popupMFAQuery?.data?.mfa_show_popup && popupMFAOpen.value && <AlertMFA onClose={popupMFAOpen.setFalse} />}
          <div className="page-content">
            {userProfile?.state === 'DEACTIVATED' && (
              <div className="acc-deactivate d-flex" onClick={() => setDeactivateVisible(true)}>
                <Icon icon={notificationNone} />
                <Typography className="text_regular-normal__14 ml-2">
                  Your company’s account has been deactivated.
                </Typography>
              </div>
            )}
            <Outlet />
          </div>
        </main>

        <EditModal visible={deactivateVisible} footer={false} closeModal={() => setDeactivateVisible(false)}>
          <div className="d-flex flex-column justify-content-center px-3">
            <div className="d-flex justify-content-center mb-5">
              <img style={{ width: 'fit-content' }} src="/assets/img/Notification.png" alt="notification" />
            </div>
            <Typography className="heading_semibold__24 text-center">
              Your company’s account has been deactivated.
            </Typography>
            <Typography className="text_regular-normal__14 text-center mt-2">
              From now on, you have <b>limited access</b> to the platform: you will not be able to manage your employees
              and contractors and generate payment for your contractors. Payroll will be no longer generated for your
              company as well. Meanwhile, you can still sign in to the platform and view your profile, employees’
              personal details, uploaded documents and previous invoices. You can also access the chat.
            </Typography>
            <div className="d-flex justify-content-center">
              <Button
                data-testid="Router-DD91CA"
                priority="primary"
                size="small"
                className="mt-5"
                onClick={() => setDeactivateVisible(false)}
              >
                Ok
              </Button>
            </div>
          </div>
        </EditModal>
      </div>
    </ErrorBoundary>
  )
}

export default Pages
